import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getProjects } from '../../services/getProjectsOfUserService';
import { getUserData } from '../../services/userService';
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const useProjects = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState("rebar");
    const [projects, setProjects] = useState([]);
    const [rebarProjects, setRebarProjects] = useState([]);
    const [metalProfileProjects, setMetalProfileProjects] = useState([]);
    const [showDeletedToast, setShowDeletedToast] = useState(false);
    const [showInfoErrorToast, setShowInfoErrorToast] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    const [userType, setUserType] = useState(0)
    const location = useLocation();

    const { i18n, t } = useTranslation();

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('deleted')) {
            setShowDeletedToast(true);
        }
        else if (searchParams.get('infoerror')) {
            setShowInfoErrorToast(true);
        }

        const fetchProjects = async () => {

            let user_data
            try{
                user_data = await getUserData()
            }
            catch{
                // getUserError
            }
            if (user_data.user_type === 3 && !user_data.delegation_active) {
                navigate(`/${i18n.language}/delegation-contract`)
            }
            const user_type = user_data.user_type
            if (user_type === 0) {
                window.location = `/${i18n.language}/inactive-account`
            }
            else {
                setUserType(user_type)
                try{
                    const response = await getProjects();
                    if (response.ok) { // Check if the request was successful
                        const data = await response.json(); // Parse the response to JSON
                        setProjects(data); // Update the state variable
                        setRebarProjects(data.filter(project => project.type === "rebar"))
                        setMetalProfileProjects(data.filter(project => project.type === "metal_profile"))
                        setIsLoading(false)
                    }
                    else {
                        var props = {
                            error_code: "OCYPVZ",
                            detail: `${t("Projeler yüklenemedi")}: ${response.statusText} (${response.status})`,
                        }
                        navigate(`/error`, { state: { props: props } })
                    }
                }
                catch{
                    var props = {
                        error_code: "2SFQI2",
                        detail: t("Sistem Hatası: Projeler yüklenemedi. Sistem yöneticisi ile irtibata geçin."),
                    }
                    navigate(`/error`, { state: { props: props } })
                }
            }
        };

        fetchProjects();
    }, [location.search]);

    return {
        activeTab,
        setActiveTab,

        projects,
        rebarProjects,
        metalProfileProjects,
        showDeletedToast,
        setShowDeletedToast,
        showInfoErrorToast,
        setShowInfoErrorToast,
        isLoading,

        userType,
    }
}

export default useProjects;
