import React, { useState, useEffect } from 'react';
import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from '../../components/Footer/Footer';
import mainStyles from '../Pages.module.css';
import styles from './LandingPage.module.css';

import Toast from 'react-bootstrap/Toast';
import { useLocation } from 'react-router-dom';

import sampleSs from '../../img/sample_ss.png'
import loadProject from '../../img/load-project.png'
import inspectProject from '../../img/inspect-project.png'
import createCuttingPlan from '../../img/create-cutting-plan.png'
import inspectCuttingPlan from '../../img/inspect-cutting-plan.png'

// import { loginUser } from '../services/authService';

import { useTranslation } from "react-i18next";

const LandingPage = () => {

    const [showToast, setShowToast] = useState(false);
    const location = useLocation();

    document.title = "Tasnifer"

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('logout')) {
            setShowToast(true);
        }
    }, [location.search]);

    const { i18n, t } = useTranslation();

    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />
            <div className={mainStyles.content}>
                <Container fluid style={{ display: 'flex', alignItems: 'center' }}>
                    <Row className={styles.landingRow}>
                        <Col md={6} xs={12}>
                            <div className={styles.landingTopText}>
                                {i18n.language === "tr"
                                    ?
                                    <>
                                        {t("İnşaat Donatıları İçin")}&nbsp;
                                        <span className={styles.landingBottomText}>
                                            {t("Kesim Optimizasyonu")}
                                        </span>

                                    </>
                                    :
                                    <>
                                        <span className={styles.landingBottomText}>
                                            {t("Kesim Optimizasyonu")}&nbsp;
                                        </span>
                                        {t("İnşaat Donatıları İçin")}
                                    </>
                                }
                            </div>
                            <div className={styles.landingText}>
                                {t("İnşaat donatıları için en az fire ile en optimum kesim planını yapıyoruz.")}
                            </div>

                        </Col>
                        <Col md={6} xs={12} className="mt-3">
                            <img src={sampleSs} className={`${styles.sampleScreenshots} img-fluid`}></img>
                        </Col>
                    </Row>
                </Container>

                <div className={`container-lg`}>
                    <Row >
                        <h2 className={styles.howItWorksHeader}>{t("Tasnifer Nedir?")}</h2>
                    </Row>
                    <Row className={`${styles.howItWorksStep} d-flex justify-content-center`}>
                        <p className='text-center'><span className='fw-bold'>Tasnifer</span> {t("Tasnifer tanım 1")}</p>
                        <hr />
                        <div className={`ratio ratio-16x9 ${styles.videoResponsive}`}>
                            <iframe className="d-flex justify-content-center" src="https://www.youtube.com/embed/1qex_LTYak8?si=A4OuwmZXy9shFvKF" title="Tasnifer" allowFullScreen></iframe>
                        </div>
                    </Row>
                </div>

                <div className={`container-lg ${styles.howItWorks}`}>
                    <Row >
                        <h2 className={styles.howItWorksHeader}>{t("Nasıl Çalışır?")}</h2>
                    </Row>
                    <Row className={styles.howItWorksStep}>
                        <Col md={7} className={styles.howItWorksStepCol}>
                            <Row>
                                <h4 className={styles.howItWorksStepHeader}>{t("1. Adım")}</h4>
                            </Row>
                            <hr></hr>
                            <Row>
                                <p>{t("1. Adım Açıklama")} </p>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <img src={loadProject} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <Row className={styles.howItWorksStep}>
                        <Col md={7} className={styles.howItWorksStepCol}>
                            <Row>
                                <h4 className={styles.howItWorksStepHeader}>{t("2. Adım")}</h4>
                            </Row>
                            <hr></hr>
                            <Row>
                                <p>{t("2. Adım Açıklama")}</p>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <img src={inspectProject} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <Row className={styles.howItWorksStep}>
                        <Col md={7} className={styles.howItWorksStepCol}>
                            <Row>
                                <h4 className={styles.howItWorksStepHeader}>{t("3. Adım")}</h4>
                            </Row>
                            <hr></hr>
                            <Row>
                                <p>{t("3. Adım Açıklama")}</p>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <img src={createCuttingPlan} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                    <Row className={styles.howItWorksStep}>
                        <Col md={7} className={styles.howItWorksStepCol}>
                            <Row>
                                <h4 className={styles.howItWorksStepHeader}>{t("4. Adım")}</h4>
                            </Row>
                            <hr></hr>
                            <Row>
                                <p>{t("4. Adım Açıklama")}</p>
                            </Row>
                        </Col>
                        <Col md={5}>
                            <img src={inspectCuttingPlan} className='img-fluid shadow-sm img-thumbnail'></img>
                        </Col>
                    </Row>
                </div>
                <Container fluid className='text-center'>
                    <p>{t("detaylı bilgi için 1")} <a href={`/${i18n.language}/user-manual`}>{t("Kullanım Rehberi")}</a>{t("detaylı bilgi için 2")} </p>
                </Container>

            </div>
            <Footer />
            <Toast
                onClose={() => setShowToast(false)}
                show={showToast}
                bg="success"
                delay={3000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Bilgi")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{t("Çıkış yapıldı.")}</Toast.Body>
            </Toast>

        </div>


    );
};

export default LandingPage;