import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

import useUnitPrices from '../../../hooks/admin/useUnitPrices';
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';

import { Link } from 'react-router-dom';

const PricesTr = () => {

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL
    const homeURL = process.env.REACT_APP_HOME_URL

    document.title = "Fiyat Bilgileri"

    const {
        isLoading,
        projectPrice,
        transferDiscount,
    } = useUnitPrices();

    const exampleWeight = 156.3

    const examplePrice = (projectPrice.unit_price * exampleWeight).toFixed(0)


    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                {isLoading ? LoadingContainer :
                    <Container className={`bg-white my-5 p-5 shadow-sm`}>

                        <h4 className='mb-3'>Tasnifer Güncel Fiyatlandırması</h4>

                        <p>Tasnifer üzerinden oluşturacağınız projelerdeki kesim planlarına erişebilmek için yüklenen detaylı metraj dosyasındaki inşaat donatıları üzerinden hesaplanan ton ağırlığı başına <span className='fw-bold'>{projectPrice.unit_price} Türk Lirası</span> ödemeniz gerekmektedir.</p>

                        <p>Örneğin yüklediğiniz projede {exampleWeight} ton demir varsa {exampleWeight} &times; {projectPrice.unit_price} = {examplePrice} ₺ ücret ödersiniz.</p>

                        <p>Havale ile ödemelerde <span className='fw-bold'>%{(transferDiscount.unit_price * 100)}</span> indirim uygulanır.</p>

                        <p>Projemiz T.C. Sanayi ve Teknoloji Bakanlığı tarafından desteklenmektedir ve KDV oranımız <span className='fw-bold'>%0</span>'dır.</p>

                        <p>Satın alım işlemleriyle ilgili detaylara Kullanım Rehberimizdeki <Link to="/tr/user-manual#purchase-manual">Satın Alım Rehberi</Link> üzerinden erişebilirsiniz.</p>

                        <p>Tüm soru görüş ve önerileriniz için {contactEmail} e-posta adresine e-posta yollayabilirsiniz.</p>

                        <p></p>


                    </Container>
                }
            </div>
            <Footer />
        </div>
    );
};

export default PricesTr;