import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';

import { useTranslation } from 'react-i18next';

function sum(array, return_type = 'string') {
    const number_array = array.map(item => Number(item))
    const total = number_array.reduce((partialSum, a) => partialSum + a, 0)
    return total
}

const nFormat = new Intl.NumberFormat();

function format(item) {
    return nFormat.format(item);
}

export const AllSummaryTable = ({ allStatistics, fontSize='12pt' }) => {
    const { t } = useTranslation();

    const allExecutionTime = sum(allStatistics.map(item => item.executionTime)).toFixed(2)
    const allLogsUsed = sum(allStatistics.map(item => item.totalLogsUsed))
    const allLogLength = format(sum(allStatistics.map(item => item.totalLogLength)))
    const allLogWeight = format(sum(allStatistics.map(item => item.totalLogWeight)))
    const allUsedLength = format(sum(allStatistics.map(item => item.totalUsedLength)))
    const allUsedWeight = format(sum(allStatistics.map(item => item.totalUsedWeight)))
    const allWaste = format(sum(allStatistics.map(item => item.totalWaste)))
    const allWastedWeight = format(sum(allStatistics.map(item => item.totalWastedWeight)))
    const allYieldPercentage = (sum(allStatistics.map(item => item.totalUsedWeight)) / sum(allStatistics.map(item => item.totalLogWeight)) * 100).toFixed(2)
    const allWastedPercentage = (100 - sum(allStatistics.map(item => item.totalUsedWeight)) / sum(allStatistics.map(item => item.totalLogWeight)) * 100).toFixed(2)

    return <div className='table-responsive' style={{fontSize: fontSize}}>
        <table className={`table border text-center align-middle`}>
            <thead>
                <tr>
                    <th>{t("Profil")}</th>
                    <th>{t("Çözüm Süresi")} (s)</th>
                    <th>{t("Toplam Boy Sayısı")}</th>
                    <th>{t("Toplam Boy Uzunluğu")} (m)</th>
                    <th>{t("Toplam Ağırlık")} (kg)</th>
                    <th>{t("Toplam Kullanılan Uzunluk")} (m)</th>
                    <th>{t("Toplam Kullanılan Ağırlık")} (kg)</th>
                    <th>{t("Toplam Fire Uzunluğu")} (m)</th>
                    <th>{t("Toplam Fire Ağırlığı")} (kg)</th>
                    <th>{t("Verim Yüzdesi")}</th>
                    <th>{t("Fire Yüzdesi")}</th>
                </tr>
            </thead>
            <tbody>
                {allStatistics.map((item, index) => (
                    <tr key={index}>
                        <td>{item.profileName}</td>
                        <td>{item.executionTime}</td>
                        <td>{item.totalLogsUsed}</td>
                        <td>{nFormat.format(item.totalLogLength)}</td>
                        <td>{nFormat.format(item.totalLogWeight)}</td>
                        <td>{nFormat.format(item.totalUsedLength)}</td>
                        <td>{nFormat.format(item.totalUsedWeight)}</td>
                        <td>{nFormat.format(item.totalWaste)}</td>
                        <td>{nFormat.format(item.totalWastedWeight)}</td>
                        <td>{item.usedPercentage}%</td>
                        <td>{item.wastedPercentage}%</td>
                    </tr>
                ))}
                <tr>
                    <th>{t("TOPLAM")}</th>
                    <th>{allExecutionTime}</th>
                    <th>{allLogsUsed}</th>
                    <th>{allLogLength}</th>
                    <th>{allLogWeight}</th>
                    <th>{allUsedLength}</th>
                    <th>{allUsedWeight}</th>
                    <th>{allWaste}</th>
                    <th>{allWastedWeight}</th>
                    <th>{allYieldPercentage}%</th>
                    <th>{allWastedPercentage}%</th>
                </tr>
            </tbody>
        </table>
    </div>
}

export const Summary = ({ currentResultSummary, allStatistics }) => {
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>
            <div className="justify-content-center">
                <Container className="border shadow-sm my-3 py-3">
                    <h5 className="text-center">{t("Mevcut Seçim Sonuç Özeti")}</h5>
                    <table className={`table table-striped-columns border my-3 text-center align-middle`}>
                        <tbody>
                            <tr>
                                <th scope="row" style={{ width: "60%" }}>{t("Toplam Boy Sayısı")}</th>
                                <td style={{ width: "40%" }}>{currentResultSummary.totalLogs}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Toplam Ağırlık")} (kg)</th>
                                <td>{nFormat.format(currentResultSummary.totalWeight)}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Fire Ağırlığı")} (kg)</th>
                                <td>{nFormat.format(currentResultSummary.wastedWeight)}</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Verim Yüzdesi")}</th>
                                <td>{currentResultSummary.yieldPercentage}%</td>
                            </tr>
                            <tr>
                                <th scope="row">{t("Fire Yüzdesi")}</th>
                                <td>{currentResultSummary.wastePercentage}%</td>
                            </tr>

                        </tbody>
                    </table>
                </Container>
            </div>
            <div className="justify-content-center">
                <Container className="border shadow-sm my-3 py-3">
                    <h5 className="text-center mb-3">{t("Tüm Sonuç Özeti")}</h5>
                    <AllSummaryTable allStatistics={allStatistics} />
                </Container>
            </div>
        </Container>
    )
}

export default Summary;
