import AppNavbarLocales from '../components/AppNavbar/locales/tr.json'
import LandingPageLocales from '../pages/LandingPage/locales/tr.json'
import FooterLocales from '../components/Footer/locales/tr.json'
import LoginLocales from '../pages/Login/locales/tr.json'
import SignupLocales from '../pages/Signup/locales/tr.json'
import ProjectsLocales from '../pages/Projects/locales/tr.json'
import ProjectRowLocales from '../components/ProjectRow/locales/tr.json'
import ProjectInfoLocales from '../pages/ProjectInfo/locales/tr.json'
import ProjectCreationLocales from '../pages/ProjectCreation/locales/tr.json'
import CpInfoLocales from '../pages/CpInfo/locales/tr.json'
import DelegationContractLocales from '../pages/DelegationContract/locales/tr.json'
import ProjectPurchaseLocales from '../pages/ProjectPurchase/locales/tr.json'

export const tr_lang_dict = Object.assign(
    {},
    AppNavbarLocales,
    LandingPageLocales,
    FooterLocales,
    LoginLocales,
    SignupLocales,
    ProjectsLocales,
    ProjectRowLocales,
    ProjectInfoLocales,
    ProjectCreationLocales,
    CpInfoLocales,
    DelegationContractLocales,
    ProjectPurchaseLocales
)
