import React, { useState } from 'react';
import { Button, Col, Form, Row, Spinner, Table, Tab, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import useUserInfos from '../../../hooks/admin/useUserInfos';
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';
import styles from '../AdminPanel.module.css'

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';

import { iso31661 } from '../../../other/iso31661'
import { iso31662 } from '../../../other/iso31662'
import { customList } from 'country-codes-list';
const telephoneCodes = customList('countryCallingCode', '{countryCode}');

function deepEqual(x, y) {
    const ok = Object.keys, tx = typeof x, ty = typeof y;
    return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
        ok(x).every(key => deepEqual(x[key], y[key]))
    ) : (x === y);
}

const UserInfo = () => {

    const {
        userInfos,
        isLoading,
        isChangeLoading,
        selectedUserInfo,
        currentUserInfo,
        activeTab,
        projectsLoading,
        projectsUser,
        projects,

        userInfoError,

        userDeleteModal,
        userDeleteLoading,
        userDeleteError,
        userDeleteSuccess,
        setUserDeleteModal,

        setCurretUserInfo,
        setActiveTab,
        handleTableClick,
        handleChangeSubmit,
        handleSearch,
        handleDeleteUser,
        handleCloseUserDeleteModal,
        handleLoadUserProjects,

    } = useUserInfos();

    const infoKeys = Object.keys(userInfos)

    if (isLoading) {
        return LoadingContainer
    }

    const homeURL = process.env.REACT_APP_HOME_URL

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <Container>
            {userDeleteModal && (
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={userDeleteModal.show || userDeleteLoading}
                    onClose={() => handleCloseUserDeleteModal()}
                >
                    <DialogTitle>
                        {"Kullanıcıyı silmek istediğinizden emin misiniz? "}
                    </DialogTitle>
                    <DialogContent>
                        <p className='text-danger'>{userDeleteModal.user} numaralı kullanıcı, tüm verileriyle birlikte TAMAMEN silinecek.</p>
                        <p className='fw-bold text-danger'>BU İŞLEM GERİ DÖNDÜRÜLEMEZ!</p>

                        <p className='fw-bold text-danger'>{userDeleteError}</p>
                        {userDeleteSuccess && <p className='fw-bold text-success'>KULLANICI BAŞARIYLA SİLİNDİ.</p>}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="success"
                            disabled={userDeleteLoading || userDeleteSuccess}
                            onClick={() => { handleCloseUserDeleteModal() }}>
                            Hayır
                        </Button>
                        <Button
                            variant="danger"
                            disabled={userDeleteLoading || userDeleteSuccess}
                            onClick={() => {
                                handleDeleteUser(userDeleteModal.user)
                            }}>
                            {userDeleteLoading ?
                                <Spinner className='spinner-border-sm' /> :
                                "Evet"
                            }
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <Row>
                <Col md={6} xs={12}>
                    <Form.Group className="mb-3">
                        <Form.Control
                            className='rounded-pill'
                            placeholder='Ara'
                            onChange={(e) => { handleSearch(e.target.value) }}
                        />
                    </Form.Group>
                    <p>Kullanıcı Sayısı: {Object.keys(userInfos).length}</p>
                    <div className='border' style={{ height: 'min(100%,150vh)', overflowY: 'auto' }}>
                        <Table bordered hover className={styles.customTable}>
                            <thead>
                                <tr>
                                    <th scope="col" className={styles.customCol} style={{ width: "12.5%" }}>ID</th>
                                    <th scope="col" className={styles.customCol} style={{ width: "60%" }}>E-posta</th>
                                    <th scope="col" className={styles.customCol} style={{ width: "12.5%" }}>Tip</th>
                                    <th scope="col" className={styles.customCol} style={{ width: "15%" }}>Kredi</th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoKeys.map((infoKey) => {
                                    return <tr
                                        key={infoKey}
                                        onClick={() => handleTableClick(infoKey)}
                                        style={{ backgroundColor: Number(infoKey) === selectedUserInfo.user_id ? '#d8dadd' : '' }}
                                    >
                                        <td className={styles.customCol}>{userInfos[infoKey].user_id}</td>
                                        <td className={styles.customCol}>{userInfos[infoKey].email}</td>
                                        <td className={styles.customCol}>{userInfos[infoKey].user_type}</td>
                                        <td className={styles.customCol}>{userInfos[infoKey].credits}</td>
                                    </tr>
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Col>

                <Col md={6} xs={12}>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="user_info">

                        <Nav className="nav-tabs nav-fill flex-row d-none d-md-flex">

                            <Col xs={6}>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="user_info"
                                        className={activeTab === 'user_info' ? styles.activeTab : styles.tabHeader}
                                        onClick={() => setActiveTab('user_info')}
                                    >
                                        {"Kullanıcı Bilgileri"}
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                            <Col xs={6}>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="cp"
                                        className={activeTab === 'cp' ? styles.activeTab : styles.tabHeader}
                                        onClick={() => setActiveTab('cp')}
                                    >
                                        {"Projeler"}
                                    </Nav.Link>
                                </Nav.Item>
                            </Col>
                        </Nav>

                        <Tab.Content>
                            <Tab.Pane eventKey="user_info" style={{ backgroundColor: 'white' }} transition={false}>

                                <Form className="border p-3"
                                    onSubmit={(e) => handleChangeSubmit(e, selectedUserInfo.user_id)}>
                                    <Container className='d-flex justify-content-between mb-2'>
                                        <Button
                                            variant='danger'
                                            disabled={selectedUserInfo.user_id === null}
                                            onClick={() => {
                                                setUserDeleteModal({ user: selectedUserInfo.user_id, show: true })
                                            }}
                                        >
                                            Kullanıcıyı Sil
                                        </Button>
                                        <Button
                                            disabled={deepEqual(currentUserInfo, selectedUserInfo) || isChangeLoading}
                                            type='submit'
                                        >{isChangeLoading ? <Spinner /> : "Değişiklikleri Onayla"}</Button>
                                    </Container>
                                    {userInfoError !== "" &&
                                        <Container className='my-3'>
                                            <p className='text-danger'>{userInfoError}</p>
                                        </Container>
                                    }
                                    <Form.Group className="mb-3">
                                        <Form.Label>ID</Form.Label>
                                        <Form.Control
                                            type="number"
                                            disabled
                                            value={currentUserInfo.user_id ? currentUserInfo.user_id : ""}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>E-posta adresi</Form.Label>
                                        <Form.Control
                                            type="email"
                                            disabled
                                            value={currentUserInfo.email ? currentUserInfo.email : ""}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Katılım Tarihi</Form.Label>
                                        <Form.Control
                                            disabled
                                            value={currentUserInfo.date ? currentUserInfo.date : ""}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Son Görülme</Form.Label>
                                        <Form.Control
                                            disabled
                                            value={currentUserInfo.last_login ? currentUserInfo.last_login : ""}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>E-posta onay durumu</Form.Label>
                                        <Form.Control
                                            type="email"
                                            disabled
                                            value={currentUserInfo.email_verified ? "Evet" : "Hayır"}
                                        />
                                    </Form.Group>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Tip</Form.Label>
                                        <Form.Select
                                            value={currentUserInfo.user_type ? currentUserInfo.user_type : 0}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, user_type: Number(e.target.value) })}
                                        >
                                            <option value={0}>0 - Askıda</option>
                                            <option value={1}>1 - Standart</option>
                                            <option value={2}>2 - Temsilci Vesileli</option>
                                            <option value={3}>3 - Temsilci</option>
                                            <option value={4}>4 - Yardımcı Yönetici</option>
                                            <option value={5}>5 - Yönetici</option>
                                        </Form.Select>
                                    </Form.Group>

                                    {currentUserInfo.user_type === 3 ?
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Temsilci Kodu</Form.Label>
                                                <Form.Control
                                                    maxLength={6}
                                                    value={currentUserInfo.delegate_code ? currentUserInfo.delegate_code.toUpperCase() : ""}
                                                    onChange={(e) => setCurretUserInfo({ ...currentUserInfo, delegate_code: e.target.value.toUpperCase() })}
                                                />
                                            </Form.Group>

                                            <Form.Group className="mb-3">
                                                <Form.Label>Temsilcilik onay durumu</Form.Label>
                                                <Form.Control
                                                    disabled
                                                    value={currentUserInfo.delegate_active ? "Evet" : "Hayır"}
                                                />
                                            </Form.Group>
                                        </>

                                        : <></>}

                                    {currentUserInfo.user_type === 2 ?
                                        <>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Temsilci Kullanıcı Kodu</Form.Label>
                                                <Form.Control
                                                    maxLength={6}
                                                    value={currentUserInfo.assigned_delegate_code}
                                                    onChange={(e) => setCurretUserInfo({ ...currentUserInfo, assigned_delegate_code: e.target.value.toUpperCase() })}
                                                />
                                            </Form.Group>
                                        </>

                                        : <></>}

                                    {/* {[2, 3, 4].includes(currentUserInfo.user_type) ?
                            <Form.Group className="mb-3">
                                <Form.Label>Kredi</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={currentUserInfo.credits ? currentUserInfo.credits : 0}
                                    onChange={(e) => setCurretUserInfo({ ...currentUserInfo, credits: Number(e.target.value) })}
                                />
                            </Form.Group> : <></>} */}

                                    <Form.Group className="mb-3">
                                        <Form.Label>Ad</Form.Label>
                                        <Form.Control
                                            maxLength={30}
                                            value={currentUserInfo.additional_info.name ? currentUserInfo.additional_info.name : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, name: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Soyad</Form.Label>
                                        <Form.Control
                                            maxLength={30}
                                            value={currentUserInfo.additional_info.surname ? currentUserInfo.additional_info.surname : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, surname: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Telefon Ülke Kodu</Form.Label>
                                        <Form.Select
                                            value={currentUserInfo.additional_info.telephone_country_code === null || currentUserInfo.additional_info.telephone_country_code === "" ?
                                                90 : currentUserInfo.additional_info.telephone_country_code
                                            }
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, telephone_country_code: e.target.value } })}
                                        >
                                            {Object.keys(telephoneCodes).map((option, index) => (

                                                <option key={index} value={option}>({telephoneCodes[option]}) +{option}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Telefon</Form.Label>
                                        <Form.Control
                                            // type="email"
                                            maxLength={30}
                                            value={currentUserInfo.additional_info.telephone ? currentUserInfo.additional_info.telephone : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, telephone: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Şirket</Form.Label>
                                        <Form.Control
                                            // type="email"
                                            maxLength={60}
                                            value={currentUserInfo.additional_info.company_name ? currentUserInfo.additional_info.company_name : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, company_name: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Vergi Dairesi</Form.Label>
                                        <Form.Control
                                            // type="email"
                                            value={currentUserInfo.additional_info.tax_office ? currentUserInfo.additional_info.tax_office : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, tax_office: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Vergi No.</Form.Label>
                                        <Form.Control
                                            // type="email"
                                            maxLength={20}
                                            value={currentUserInfo.additional_info.taxno ? currentUserInfo.additional_info.taxno : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, taxno: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Ülke</Form.Label>
                                        <Form.Select
                                            value={currentUserInfo.additional_info.country || ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, country: e.target.value, province: "" } })}
                                        >
                                            <option value="">Seçiniz</option>
                                            {iso31661.map((option, index) => (
                                                <option key={index} value={option.numeric}>{option.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Şehir / Eyalet</Form.Label>
                                        <Form.Select
                                            value={currentUserInfo.additional_info.province ? currentUserInfo.additional_info.province : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, province: e.target.value } })}
                                        >
                                            <option value="">Seçiniz</option>
                                            {iso31662.filter((state) => {
                                                let country = iso31661.filter((obj) => obj.numeric === currentUserInfo.additional_info.country)
                                                if (country.length === 0) {
                                                    return false;
                                                }
                                                else {
                                                    country = country[0]
                                                    return state.parent === country.alpha2
                                                }
                                            }).map((option, index) => (
                                                <option key={index} value={option.code}>{option.name}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>İlçe</Form.Label>
                                        <Form.Control
                                            maxLength={20}
                                            value={currentUserInfo.additional_info.county ? currentUserInfo.additional_info.county : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, county: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Mahalle</Form.Label>
                                        <Form.Control
                                            maxLength={40}
                                            value={currentUserInfo.additional_info.street ? currentUserInfo.additional_info.street : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, street: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Posta Kodu</Form.Label>
                                        <Form.Control
                                            maxLength={10}
                                            value={currentUserInfo.additional_info.zipcode ? currentUserInfo.additional_info.zipcode : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, zipcode: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Adres Satırı 1</Form.Label>
                                        <Form.Control
                                            maxLength={100}
                                            value={currentUserInfo.additional_info.address_line1 ? currentUserInfo.additional_info.address_line1 : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, address_line1: e.target.value } })}
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Adres Satırı 2</Form.Label>
                                        <Form.Control
                                            maxLength={100}
                                            value={currentUserInfo.additional_info.address_line2 ? currentUserInfo.additional_info.address_line2 : ""}
                                            onChange={(e) => setCurretUserInfo({ ...currentUserInfo, additional_info: { ...currentUserInfo.additional_info, address_line2: e.target.value } })}
                                        />
                                    </Form.Group>
                                </Form>

                            </Tab.Pane>
                            <Tab.Pane eventKey="cp" style={{ backgroundColor: 'white' }} transition={false} className='border p-2'>

                                <Button
                                    disabled={selectedUserInfo.user_id === null || projectsLoading}
                                    onClick={() => handleLoadUserProjects()}
                                    className='m-2'
                                >
                                    {projectsLoading ? <Spinner /> :
                                        `Projeleri Getir (${(selectedUserInfo.user_id)})`}
                                </Button>

                                <h5>{projectsUser} no'lu kullanıcının Projeleri {projects !== null ? `(${projects.length} adet)` : ""}</h5>

                                {projects !== null ?
                                    <Table bordered hover className={styles.customTable}>
                                        <thead>
                                            <tr>
                                                <th scope="col" className={styles.customCol} style={{ width: "10%" }}>ID</th>
                                                <th scope="col" className={styles.customCol} style={{ width: "50%" }}>Tarih</th>
                                                <th scope="col" className={styles.customCol} style={{ width: "20%" }}>Adı</th>
                                                <th scope="col" className={styles.customCol} style={{ width: "20%" }}>Aktif?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {projects.map((project) => {
                                                return <tr key={project.id} onClick={()=>openInNewTab(`${homeURL}/project-info/${project.id}`)}>
                                                    <td className={styles.customCol}>{project.id}</td>
                                                    <td className={styles.customCol}>{(new Date(project.creationTime)).toLocaleDateString("tr")} {(new Date(project.creationTime)).toLocaleTimeString("tr")}</td>
                                                    <td className={styles.customCol}>{project.name}</td>
                                                    <td className={styles.customCol}>{project.activated ? "Evet" : "Hayır"}</td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </Table>
                                    : <></>}

                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>

                </Col>
            </Row>
        </Container>
    );
};

export default UserInfo;
