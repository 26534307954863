import { useState, useEffect } from 'react';
import { getProject } from '../../services/getProjectService';
import { deleteProject } from '../../services/deleteProjectService';
import { getCps } from '../../services/getCpsOfProject';
import { useLocation } from 'react-router-dom';
import { downloadProject } from '../../services/projectDownloadService';
import { getUserData } from '../../services/userService';
import { getSingleUnitPrice } from '../../services/getSingleUnitPrice';
import { isProjectPurchasable } from '../../services/paymentServices/projectPurchasableService';
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const createNodes = (inputDictionary, all_text) => {

    if (Array.isArray(inputDictionary["Tümü"])) {
        const nodes = [{
            value: 1,
            label: all_text
        }]
        const rebarListDict = {
            1: inputDictionary["Tümü"]
        }

        return [nodes, rebarListDict]
    }

    let counter = 0
    let rebarListDict = {}
    let nodes = {}

    function transform(input) {
        const children = [];

        for (let key in input) {
            counter++;
            if (Array.isArray(input[key])) {
                children.push({
                    value: String(counter),
                    label: key,
                })
                rebarListDict[counter] = input[key];
            } else {
                children.push({
                    value: String(counter),
                    label: key,
                    children: transform(input[key])
                });
            }
        }

        return children;
    }

    function traverse(input) {
        for (let key in input) {
            counter++;
            nodes = {
                value: String(counter),
                label: key,
                children: transform(input[key])
            };
        }
    }

    traverse(inputDictionary)

    nodes["label"] = all_text

    return [[nodes], rebarListDict]

}

const useProjectInfo = (id) => {

    /*
        3 views of the page
        1- Rebar Project
        2- Metal Profile Project with profile info unset
        3- Metal Profile Project with profile info set
    */
    const { i18n, t } = useTranslation()

    const navigate = useNavigate();
    // 1,2,3
    const [name, setName] = useState('')
    const [isLoading, setIsLoading] = useState(true)
    const [userType, setUserType] = useState(0)
    const [ownProject, setOwnProject] = useState(true)
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteScreen, setDeleteScreen] = useState(false);
    const [showDeletedToast, setShowDeletedToast] = useState(false);
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [projectType, setProjectType] = useState(null) // rebar or metal_profile

    // 2,3
    const [userSetProfileInfo, setUserSetProfileInfo] = useState(false)
    const [profileInfo, setProfileInfo] = useState({})

    // 3
    const [allPieces, setAllPieces] = useState([])
    const [pieces, setPieces] = useState([])
    const [selectedProfiles, setSelectedProfiles] = useState([])

    // 1,3
    const [pDetails, setPDetails] = useState({})

    const [projectUser, setProjectUser] = useState("")
    const [projectCreationTime, setProjectCreationTime] = useState("")
    const [projectUploadedFileType, setProjectUploadedFileType] = useState("")
    const [projectActivated, setProjectActivated] = useState(false)
    const [projectTheoreticalWeight, setProjectTheoreticalWeight] = useState(0)
    const [projectTheoreticalLength, setProjectTheoreticalLength] = useState(0)
    const [unitPrice, setUnitPrice] = useState(0)
    const [unitDiscount, setUnitDiscount] = useState(0)
    const [unitPriceType, setUnitPriceType] = useState(0)
    const [priceCalculationMethod, setPriceCalculationMethod] = useState('weight_per_1ton')
    const [purchasable, setPurchasable] = useState(true)
    const [warningMessage, setWarningMessage] = useState({ show: false, message: "" })
    const [cps, setCps] = useState([])
    const [activeTab, setActiveTab] = useState('first');

    // 1
    const [layoutPlan, setLayoutPlan] = useState({ "Tümü": {} })
    const [nodes, setNodes] = useState([])
    const [donatiDict, setDonatiDict] = useState({})
    const [rebarListDict, setRebarListDict] = useState({})
    const [logLength, setLogLength] = useState(0)
    const [floorAvailable, setFloorAvailable] = useState(0)
    const [bindirmeOrani, setBindirmeOrani] = useState(0)
    const [explanations, setExplanations] = useState([]);


    const location = useLocation();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('deleted')) {
            setShowDeletedToast(true);
        }
        async function fetchData() {

            let user_data
            try {
                user_data = await getUserData()
            }
            catch {
                // getUserError
            }
            if (user_data.user_type === 3 && !user_data.delegation_active) {
                navigate(`/${i18n.language}/delegation-contract`)
            }
            const user_id = user_data.id
            const user_type = user_data.user_type
            setUserType(user_type)
            if (user_type === 0) {
                window.location = `/${i18n.language}/inactive-account`
            }
            else {
                let projectResponse;
                let cpsResponse;
                let unitPriceResponse;
                let projectPurchasableResponse;

                try {
                    projectResponse = await getProject(id);
                }
                catch {
                    var props = {
                        error_code: "K6LCFT",
                        detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                    }

                    navigate(`/error`, { state: { props: props } })
                }

                if (!projectResponse.ok) {
                    window.location = `/${i18n.language}/projects?infoerror=true`
                }
                const projectResponseData = await projectResponse.json();
                const _projectType = projectResponseData.projectType
                setProjectType(_projectType)
                let unitPriceKey, _priceCalculationMethod
                if(_projectType === 'rebar'){
                    unitPriceKey = 'project'
                    _priceCalculationMethod = 'weight_per_1ton'
                }
                else{
                    unitPriceKey = 'metal_profile_project'
                    _priceCalculationMethod = 'length_per_1000m'
                    setPriceCalculationMethod('length_per_1000m')
                }
                setName(projectResponseData.name)
                setProjectCreationTime(projectResponseData.creationTime)
                setProjectUser(projectResponseData.user)
                const projectOwnerId = Number(projectResponseData.user.match(/(?!\()\d+(?=\))/g)[0])
                setOwnProject(projectOwnerId === user_id)
                setProjectUploadedFileType(projectResponseData.uploadedFileType)
                setProjectActivated(projectResponseData.activated)
                setProjectTheoreticalWeight(projectResponseData.theoreticalWeight)
                setProjectTheoreticalLength(projectResponseData.theoreticalLength)
                setCps(projectResponseData.cuttingPlans)
                if (projectResponseData.projectType === "metal_profile") {
                    setUserSetProfileInfo(projectResponseData.userSetProfileInfo)
                    setPieces(projectResponseData.pieces)
                    setAllPieces(projectResponseData.pieces)
                    setProfileInfo(projectResponseData.optimization_groups)
                }
                else {
                    setLogLength(projectResponseData.logLength)
                    setFloorAvailable(projectResponseData.floorAvailable)
                    setBindirmeOrani(projectResponseData.bindirmeOrani)
                    if ("uyariMesaji" in projectResponseData) {
                        const _uyariMesaji = projectResponseData.uyariMesaji
                        setWarningMessage({ show: true, message: _uyariMesaji })
                    }
                    setDonatiDict(projectResponseData.donatiDict)
                    setLayoutPlan(projectResponseData.yerlesimPlani)
                    

                    const [_nodes, _rebarListDict] = createNodes(projectResponseData.yerlesimPlani, t("Tümü"));

                    setNodes(_nodes)
                    setRebarListDict(_rebarListDict)

                    const _explanations = []
                    Object.keys(projectResponseData.donatiDict).forEach((key) => {
                        if (!(_explanations.includes(projectResponseData.donatiDict[key]["aciklama"].trim()))) {
                            _explanations.push(projectResponseData.donatiDict[key]["aciklama"].trim())
                        }
                    })

                    _explanations.sort((a, b) => a.localeCompare(b, 'tr'))

                    setExplanations(_explanations)
                }

                try {
                    if (user_type === 3) {
                        unitPriceResponse = await getSingleUnitPrice(`${unitPriceKey}_delegate`);
                    }
                    else if (user_type === 2) {
                        unitPriceResponse = await getSingleUnitPrice(`${unitPriceKey}_with_delegation`);
                    }
                    else {
                        unitPriceResponse = await getSingleUnitPrice(unitPriceKey);
                    }
                }
                catch {
                    var props = {
                        error_code: "TSL23H",
                        detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                    }

                    navigate(`/error`, { state: { props: props } })
                }
                
                if(!unitPriceResponse.ok) {
                    window.location = `/${i18n.language}/projects?infoerror=true`
                }
                const unitPriceData = await unitPriceResponse.json();
                setUnitPrice(unitPriceData.unit_price)
                setUnitDiscount(unitPriceData.unit_discount)
                setUnitPriceType(unitPriceData.type)

                try {
                    projectPurchasableResponse = await isProjectPurchasable(id);
                }
                catch {
                    var props = {
                        error_code: "VRWGPK",
                        detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                    }

                    navigate(`/error`, { state: { props: props } })
                }

                if (!projectPurchasableResponse.ok) {
                    window.location = `/${i18n.language}/projects?infoerror=true`
                }

                const projectPurchasableData = await projectPurchasableResponse.json();
                setPurchasable(projectPurchasableData.purchasable)

                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    const handleDeleteProject = async () => {
        try {
            const response = await deleteProject(id)
            if (response.ok) {
                window.location = `/${i18n.language}/projects?deleted=true`;
            }
            else {
                setIsDeleting(false);
                setShowErrorToast(true);
            }
        }
        catch {
            var props = {
                error_code: "YCLYT5",
                detail: t("Sistem Hatası: Proje silinemedi. Sistem yöneticisi ile irtibata geçin."),
            }

            navigate(`/error`, { state: { props: props } })
        }

    }

    const handleDownloadProject = async () => {
        try {
            const response = await downloadProject(id)
            if (response.ok) {
                const data = await response.json()
                const link = document.createElement('a');
                link.href = data.url;
                link.download = 'project.xlsx';
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            else {
                var props = {
                    error_code: "GO9568",
                    detail: `${t("Proje indirilemedi")}: ${response.statusText} (${response.status})`,
                }
                navigate(`/error`, { state: { props: props } })
            }
        }
        catch {
            var props = {
                error_code: "4KDVI0",
                detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
            }

            navigate(`/error`, { state: { props: props } })
        }

    }

    return {
        isLoading,
        name,
        userType,
        ownProject,
        isDeleting,
        setIsDeleting,
        deleteScreen,
        setDeleteScreen,
        showDeletedToast,
        setShowDeletedToast,
        showErrorToast,
        setShowErrorToast,

        projectType,
        userSetProfileInfo,
        profileInfo,
        setProfileInfo,
        
        pieces,
        setPieces,

        projectUser,
        projectCreationTime,
        projectUploadedFileType,
        projectActivated,
        projectTheoreticalWeight,
        projectTheoreticalLength,

        priceCalculationMethod,
        unitPrice,
        unitDiscount,
        unitPriceType,

        purchasable,
        warningMessage,
        cps,
        activeTab,
        setActiveTab,

        layoutPlan,
        nodes,
        donatiDict,
        rebarListDict,
        logLength,
        floorAvailable,
        explanations,
        bindirmeOrani,

        handleDeleteProject,
        handleDownloadProject,
    }
};

export default useProjectInfo;