import React from 'react';
import AppNavbar from '../../../components/AppNavbar/AppNavbar';
import Container from 'react-bootstrap/Container';
import Footer from '../../../components/Footer/Footer';
import mainStyles from '../../Pages.module.css';

import useUnitPrices from '../../../hooks/admin/useUnitPrices';
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';

import { Link } from 'react-router-dom';

const PricesEn = () => {

    const contactEmail = process.env.REACT_APP_CONTACT_EMAIL;
    const homeURL = process.env.REACT_APP_HOME_URL;

    document.title = "Price Information";

    const {
        isLoading,
        projectPrice,
        transferDiscount,
    } = useUnitPrices();

    const exampleWeight = 156.3;

    const examplePrice = (projectPrice.unit_price * exampleWeight).toFixed(0);


    return (
        <div className={mainStyles.flexWrapper}>
            <AppNavbar />

            <div className={mainStyles.content}>
                {isLoading ? LoadingContainer :
                    <Container className={`bg-white my-5 p-5 shadow-sm`}>

                        <h4 className='mb-3'>Current Pricing for Tasnifer</h4>

                        <p>To access cutting plans for projects created on Tasnifer, you need to pay <span className='fw-bold'>{projectPrice.unit_price} Turkish Liras</span> per ton weight calculated based on construction reinforcements in the detailed metering file uploaded.</p>

                        <p>For example, if there are {exampleWeight} tons of iron in the project you uploaded, you will pay {exampleWeight} &times; {projectPrice.unit_price} = {examplePrice} ₺.</p>

                        <p>A discount of <span className='fw-bold'>%{(transferDiscount.unit_price * 100)}</span> is applied for payments made by bank transfer.</p>

                        <p>Our project is supported by the Ministry of Industry and Technology of the Republic of Turkey, and our VAT rate is <span className='fw-bold'>%0</span>.</p>

                        <p>You can access details about purchasing transactions through the Purchase Manual in our User Manual <Link to="/en/user-manual#purchase-manual">here</Link>.</p>

                        <p>For all your questions, opinions, and suggestions, you can send an email to {contactEmail}.</p>

                        <p></p>


                    </Container>
                }
            </div>
            <Footer />
        </div>
    );
};

export default PricesEn;
