import apiClient from "./apiClient";

const createCp = async (
    projectType,
    id, 
    cpName,
    optimizationInput, 
    diameters, 
    useSubstitutes, 
    solveFloorByFloor, 
    minBarLength, 
    selectedFloors
) => {
    const url = `${process.env.REACT_APP_API_URL}/api/optimize/`;

    let body = {
        "projectId": id,
        "cpName": cpName,
    }
    if(projectType === 'rebar') {
        body = {
            ...body,
            "optimizationInput": optimizationInput,
            "diameters": diameters,
            "useSubstitutes": useSubstitutes,
            "solveFloorByFloor": solveFloorByFloor,
            "minOutageLength": minBarLength,
            "selectedFloors": selectedFloors,
        }
    }

    const response = await apiClient(url, {
        method: 'POST',
        body: JSON.stringify(body),
    })
    return response;

};

export { createCp };
