import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Toast from 'react-bootstrap/Toast';

import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Footer from '../../components/Footer/Footer';
import styles from './ProjectCreation.module.css'
import mainStyles from '../Pages.module.css';
import useProjectCreation from '../../hooks/useProjectCreationNew/useProjectCreation';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { BiInfoCircle, } from 'react-icons/bi';
import { AiFillWarning, } from 'react-icons/ai';

import { rebarLengthInfo, overlapFactorInfo } from '../../infoContents'
import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';
import InfoNote from '../../components/InfoNote/InfoNote';

import { useTranslation } from 'react-i18next';

import { FileUploader } from "react-drag-drop-files";

const ProjectCreation = () => {

  const {
    setName,

    projectType,
    setProjectType,

    projectUploadedFileType,
    setProjectUploadedFileType,

    uploadedFile,
    setUploadedFile,

    setLogLength,
    setBindirmeOrani,
    errorToast,
    setErrorToast,
    isLoading,
    handleSubmit,
    pageLoading,
  } = useProjectCreation()

  const { i18n, t } = useTranslation();

  document.title = t("Proje Oluştur")

  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogContent, setDialogContent] = useState(<div></div>)

  const proceedButtonDisabled = projectType === "select" || projectUploadedFileType === "select" || uploadedFile === null

  return (

    <div className={`${mainStyles.flexWrapper} position-relative`}>
      {dialogOpen &&
        <Dialog
          maxWidth="xs"
          fullWidth={true}
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}>
          <DialogContent>
            {dialogContent}
          </DialogContent>
        </Dialog>}
      <AppNavbar />
      <div className={mainStyles.content}>

        {pageLoading ? LoadingContainer :
          <>
            {isLoading && (
              <Backdrop
                sx={{
                  color: '#fff',
                  bgcolor: 'rgba(0,0,0,0.7)',
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
                open={isLoading}
              >
                <p className="mt-2 text-center">{t("Proje yüklenirken lütfen bekleyiniz. Sayfayı kapatmayınız.")}</p>
                <CircularProgress color="inherit" />
              </Backdrop>

            )}

            <Container className="p-4 my-4 bg-white border" style={{
              minHeight: "max(70vh,600px)",
              minWidth: "80vw",
            }}>
              <h2 className='fw-bold'>{t("Proje Oluştur")}</h2>
              <Form onSubmit={handleSubmit}>
                <Row className='my-3'>
                  <Col md={3} className='d-flex align-items-center'>
                    <span
                      style={{
                        fontSize: "1.0rem",
                        color: "#555555"
                      }}
                    >
                      {t("Proje ismi")}:
                    </span>
                  </Col>
                  <Col md={9} className='d-flex align-items-center'>
                    <Form.Control
                      required
                      type="text"
                      placeholder={t("Proje ismi giriniz")}
                      maxLength={25}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Col>
                </Row>

                <Row className='mb-3'>
                  <Col md={3} className='d-flex align-items-center'>
                    <span
                      style={{
                        fontSize: "1.0rem",
                        color: "#555555"
                      }}
                    >
                      {t("Proje türü")}:
                    </span>
                  </Col>
                  <Col md={9}>
                    <Form.Select
                      defaultValue="select"
                      onChange={(e) => {
                        setProjectType(e.target.value)
                        setProjectUploadedFileType("select")
                        setUploadedFile(null)
                      }}>
                      <option value="select">{t("Seçiniz")}</option>
                      <option value="rebar">{t("Donatı")}</option>
                      <option value="metal_profile">{t("Profil")}</option>
                    </Form.Select>
                  </Col>
                </Row>
                {
                  projectType === "rebar" &&
                  <div className='mb-3 d-flex justify-content-end'>
                    <i>{t("Detaylı metraj dosyasını yüklemeden önce, bu metrajların çizim paftalarıyla uyumlu olup olmadığını kontrol ediniz.")}</i>
                  </div>
                }
                <Row className='mb-3'>
                  <Col md={3} className='d-flex align-items-center'>
                    <span
                      style={{
                        fontSize: "1.0rem",
                        color: "#555555"
                      }}
                    >
                      {t("Yüklenecek dosya türü")}:
                    </span>
                  </Col>
                  <Col md={9}>
                    <Form.Select
                      disabled={projectType === "select"}
                      defaultValue="select"
                      value={projectUploadedFileType}
                      onChange={(e) => {
                        setProjectUploadedFileType(e.target.value)
                        setUploadedFile(null)
                      }}
                    >
                      <option value="select">{t("Seçiniz")}</option>
                      {
                        projectType === "rebar" ?
                          <>
                            <option value="rebar_ide_txt">{t("ideCAD Statik txt")}</option>
                            <option value="rebar_sta_xlsx">{t("STA4Cad Excel")}</option>
                            <option value="rebar_custom_xlsx">{t("Diğer Excel")}</option>
                          </> :
                          <>
                            <option value="profile_ide_txt">{t("ideCAD Statik txt")}</option>
                            <option value="profile_xsteel_xlsx">{t("XSteel Excel")}</option>
                            <option value="profile_sta_xlsx">{t("STASteel ve Diğer Excel")}</option>
                          </>
                      }

                    </Form.Select>
                  </Col>
                </Row>

                {projectUploadedFileType === "rebar_ide_txt" &&
                  <div className='mb-3 d-flex justify-content-end'>
                    <i>{t("projectcreation_ideupload1")} <a href={`/${i18n.language}/user-manual#ide-upload`} className={styles.clickableLink}>{t("projectcreation_ideupload2")}</a>{t("projectcreation_ideupload3")}</i>
                  </div>
                }

                {projectUploadedFileType === "rebar_sta_xlsx" &&
                  <div className='mb-3 d-flex justify-content-end'>
                    <i>{t("projectcreation_staupload1")} <a href={`/${i18n.language}/user-manual#sta-upload`} className={styles.clickableLink}>{t("projectcreation_staupload2")}</a>{t("projectcreation_staupload3")}</i>
                  </div>
                }

                {projectUploadedFileType === "rebar_custom_xlsx" &&
                  <div className='mb-3 d-flex justify-content-end'>
                    <i>{t("projectcreation_excelupload1")} <a href={`/${i18n.language}/user-manual#excel-upload`} className={styles.clickableLink}>{t("projectcreation_excelupload2")}</a>{t("projectcreation_excelupload3")}</i>
                  </div>
                }

                <Row className='mb-3'>
                  <Col md={3} className='d-flex align-items-start'>
                    <span
                      style={{
                        fontSize: "1.0rem",
                        color: "#555555"
                      }}
                    >
                      {t("Dosya yükle")}:
                    </span>
                  </Col>
                  <Col md={9}>
                    <FileUploader
                      handleChange={(file) => setUploadedFile(file)}
                      disabled={projectUploadedFileType === "select"}
                      types={projectUploadedFileType === undefined ? [] : projectUploadedFileType.includes("txt") ? ["txt"] : ["xlsx"]}
                      fileOrFiles={uploadedFile}
                      maxSize={50} // MB
                      hoverTitle=" "
                      children={
                        <div style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          minHeight: "130px",
                          minWidth: "56vw",
                          border: "2px dashed #ccc",
                          backgroundColor: projectUploadedFileType === "select" ? "#eaecef" : "#f8f9fa",
                          padding: "20px",
                        }}>
                          {uploadedFile ? (
                            <div style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <div>
                                <span style={{ color: "#555555" }}>
                                  {t("Yüklendi")}:{" "}
                                </span>
                                <span style={{ color: "#555555", fontWeight: "bold" }}>
                                  {uploadedFile.name}
                                </span>
                              </div>
                              <div className='mt-2 text-center'>
                                <span
                                  style={{
                                    color: "#555555",
                                    fontSize: "0.8rem",
                                  }}
                                >
                                  {t("Dosyayı değiştirmek için tekar tıklayın veya sürükleyin.")}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <span style={{ color: "#555555" }}>
                              {projectUploadedFileType === "select" ?
                                t("Dosya yüklemek için proje türü ve dosya türü seçiniz.")
                                :
                                t("Dosyayı buraya sürükleyin veya tıklayın.")
                              }
                            </span>
                          )}
                        </div>
                      }
                    />

                  </Col>
                </Row>
                {
                  projectType === "rebar" &&
                  (

                    <>

                      <Row className='mb-3'>
                        <Col md={3} className='d-flex align-items-center'>
                          <span
                            style={{
                              fontSize: "1.0rem",
                              color: "#555555"
                            }}
                          >
                            {t("Kesim yapılan demir uzunluğu (cm)")}: {' '}
                            <InfoNote content={rebarLengthInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} />
                          </span>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            defaultValue="1200"
                            type="number"
                            placeholder="Uzunluk"
                            min="1"
                            onChange={(e) => setLogLength(e.target.value)}
                          />
                        </Col>
                      </Row>
                      <Row className='mb-3'>
                        <Col md={3} className='d-flex align-items-center'>
                          <span
                            style={{
                              fontSize: "1.0rem",
                              color: "#555555"
                            }}
                          >
                            {t("Bindirme Katsayısı")} {' '}
                            <InfoNote content={overlapFactorInfo[i18n.language]} setDialogContent={setDialogContent} setDialogOpen={setDialogOpen} />
                          </span>
                        </Col>
                        <Col md={9}>
                          <Form.Control
                            type="number"
                            min="0"
                            max="5"
                            step="0.01"
                            required={true}
                            placeholder="Bindirme oranı"
                            defaultValue={"1"}
                            onChange={(e) => setBindirmeOrani(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </>
                  )
                }
                <div className='text-center' style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "30px"
                }}>
                  <Button
                    type='submit'
                    size="lg"
                    className='px-5 fw-bold'
                    disabled={proceedButtonDisabled}
                  >
                    {t("İLERLE")}
                  </Button>
                </div>
              </Form>

            </Container>
          </>
        }
      </div>
      <Footer />

      <Toast
        onClose={() => setErrorToast({ show: false, message: '' })}
        show={errorToast.show}
        bg="danger"
        delay={6000}
        autohide
        style={{
          position: 'fixed',
          bottom: 20,
          left: 20,
          zIndex: 9999,
        }}
      >
        <Toast.Header>
          <strong className="me-auto">{t("Hata")}</strong>
        </Toast.Header>
        <Toast.Body style={{ color: 'white' }}>{errorToast.message}</Toast.Body>
      </Toast>
    </div>


  );
};

export default ProjectCreation;
