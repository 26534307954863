import React from 'react';

import Pattern from '../../../components/Pattern/Pattern';
import SubstitutionTable from '../../../components/CpInfoTables/SubstitututionTable/SubstitutionTable';
import WasteTable from '../../../components/CpInfoTables/WasteTable/WasteTable';

import styles from './PrintableCpInfo.module.css'

import watermark1 from '../../../img/watermark-1.png'
import watermark2 from '../../../img/watermark-2.png'

import { useTranslation } from 'react-i18next';

const PrintableCpInfo = React.forwardRef((props, ref) => {
    const projectName = props.projectName
    const cpName = props.cpName
    const floorAvailable = props.floorAvailable;
    const currentResultSummary = props.currentResultSummary;
    const allFloors = props.allFloors;
    const substitutes = props.currentSubstitutes;
    const checkedDiameter = props.checkedDiameter
    const checkedFloors = props.checkedFloors
    const patterns = props.currentPatterns;
    const showedPatterns = props.showedPatterns;
    const wastes = props.currentWastes;

    const donatiDict = props.donatiDict
    const bindirmeOrani = props.bindirmeOrani
    const ratio = props.ratio

    const { t } = useTranslation();

    let subAvailable;
    if (substitutes) {
        subAvailable = substitutes.length !== 0
    }
    else {
        subAvailable = false;
    }

    let wasteAvailable;
    if (wastes) {
        wasteAvailable = Object.keys(wastes).length !== 0
    }
    else {
        wasteAvailable = false;
    }

    let floorsStr = ""
    if (floorAvailable) {
        floorsStr = allFloors
            .filter((_, index) => checkedFloors.includes(index))
            .join(", ");
    }

    return (
        <div ref={ref}>
            <div className={`${styles.centerContent}`} style={{ pageBreakAfter: 'always' }}>
                <img src={watermark1} style={{ height: '40px', marginBottom: '10pt' }}></img>
                <h5>{t("Sonuç Özeti")}</h5>
                <table className={`table table-sm table-striped-columns border my-3`}>
                    <tbody>
                        <tr>
                            <th scope="row">{t("Proje / Kesim Planı")}</th>
                            <td>{projectName} / {cpName}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Çap")}</th>
                            <td>Φ{checkedDiameter}</td>
                        </tr>
                        {floorAvailable &&
                            <>
                                <tr>
                                    <th scope="row">{t("Katlar")}</th>
                                    <td>{floorsStr}</td>
                                </tr>
                            </>
                        }
                        <tr>
                            <th scope="row">{t("Toplam Boy Sayısı")}</th>
                            <td>{currentResultSummary.totalLogs}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Toplam Ağırlık (kg)")}</th>
                            <td>{currentResultSummary.totalWeight}</td>
                        </tr>
                        {floorAvailable &&
                            <>
                                <tr>
                                    <th scope="row">{t("Sipariş Boy Sayısı")}</th>
                                    <td>{currentResultSummary.orderLogs}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{t("Sipariş Ağırlığı (kg)")}</th>
                                    <td>{currentResultSummary.orderWeight}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{t("Elde Kalan Fire Ağırlığı (kg)")}</th>
                                    <td>{currentResultSummary.outageWeight}</td>
                                </tr>
                                <tr>
                                    <th scope="row">{t("Teorik Fire Ağırlığı (kg)")}</th>
                                    <td>{currentResultSummary.wastedWeight}</td>
                                </tr>
                            </>
                        }
                        {subAvailable &&
                            <tr>
                                <th scope="row">{t("Yerine Kullanım Ağırlığı (kg)")}</th>
                                <td>{currentResultSummary.subWeight}</td>
                            </tr>
                        }
                        <tr>
                            <th scope="row">{t("Verim Yüzdesi")}</th>
                            <td>{currentResultSummary.yieldPercentage}%</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Fire Yüzdesi")}</th>
                            <td>{currentResultSummary.wastePercentage}%</td>
                        </tr>
                    </tbody>
                </table>
            </div>


            <div className="row justify-content-end align-items-center">
                <div className="col-4">
                    <h5 className="text-center py-2">{t("Kesimler")}</h5>
                </div>
                <div className="col-4 text-end">
                    <img src={watermark2} style={{ height: '25px', marginBottom: '10pt' }}></img>
                </div>
            </div>
            {showedPatterns.map((pattern, i) => (
                <Pattern
                    key={i}
                    floorAvailable={floorAvailable}
                    pattern={pattern}
                    allFloors={props.allFloors}
                    donatiDict={donatiDict}
                    checkedFloors={checkedFloors}
                    bindirmeOrani={bindirmeOrani}
                    ratio={ratio}
                    print={true}
                />
            ))}

            <div className="row justify-content-end align-items-center">
                <div className="col-4">
                    <h5 className="text-center py-2">{t("Yerine Kullanımlar")}</h5>
                </div>
                <div className="col-4 text-end">
                    <img src={watermark2} style={{ height: '25px', marginBottom: '10pt' }}></img>
                </div>
            </div>
            {subAvailable ?
                <SubstitutionTable substitutes={substitutes} donatiDict={donatiDict} floorAvailable={floorAvailable} ratio={.8} />
                : <p className="text-center">{t("Yerine kullanım mevcut değil.")}</p>}


            <div className="row justify-content-end align-items-center">
                <div className="col-4">
                    <h5 className="text-center py-2">{t("Fire Listesi")}</h5>
                </div>
                <div className="col-4 text-end">
                    <img src={watermark2} style={{ height: '25px', marginBottom: '10pt' }}></img>
                </div>
            </div>
            {wasteAvailable ?
                <WasteTable wastes={wastes} ratio={.8} />
                : <p className="text-center">{t("Fire listesi mevcut değil.")}</p>}

        </div>
    );
});

export default PrintableCpInfo;