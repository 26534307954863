import React from "react";

import { Tab, Nav, Row, Col, Container } from "react-bootstrap";
import RebarInspection from './RebarTabPages/RebarInspection';
import LayoutStats from './RebarTabPages/LayoutStats';

import { AiFillWarning } from 'react-icons/ai';
import { useTranslation } from "react-i18next";


import styles from './ProjectInfo.module.css';
import ProfileInspection from "./MetalProfileTabPages/ProfileInspection";
import ProfileStats from "./MetalProfileTabPages/ProfileStats";
import CuttingPlans from './MetalProfileTabPages/CuttingPlans';
import ProjectDetails from './MetalProfileTabPages/ProjectDetails';

const MetalProfileProjectInfoSet = (
    {
        activeTab,
        setActiveTab,
        id,
        name,
        profileInfo,
        setProfileInfo,
        pieces,
        projectCreationTime,
        projectTheoreticalWeight,
        projectTheoreticalLength,
        projectUser,
        projectUploadedFileType,
        projectActivated,
        priceCalculationMethod,
        unitPrice,
        unitDiscount,
        unitPriceType,
        purchasable,
        userType,
        ownProject,
        cps,
    }
) => {
    const { i18n, t } = useTranslation();

    return (
        <>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                <Nav className="nav-tabs nav-fill flex-row d-none d-md-flex">
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="first"
                                className={activeTab === 'first' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('first')}
                            >
                                {t("Parçalar")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="fourth"
                                className={activeTab === 'fourth' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('fourth')}
                            >
                                {t("Profiller")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="second"
                                className={activeTab === 'second' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('second')}
                            >
                                {t("Kesim Planları")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="third"
                                className={activeTab === 'third' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('third')}
                            >
                                {t("Proje Bilgileri")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                </Nav>

                <Nav className="nav-tabs nav-fill flex-row d-flex d-md-none">
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="first"
                                className={activeTab === 'first' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('first')}
                            >
                                {t("Parça.")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="fourth"
                                className={activeTab === 'fourth' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('fourth')}
                            >
                                {t("Profil.")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="second"
                                className={activeTab === 'second' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('second')}
                            >
                                {t("K. Pln.")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                    <Col xs={3}>
                        <Nav.Item>
                            <Nav.Link
                                eventKey="third"
                                className={activeTab === 'third' ? styles.activeTab : styles.tabHeader}
                                onClick={() => setActiveTab('third')}
                            >
                                {t("P. Blg.")}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="first" style={{ backgroundColor: 'white' }} transition={false}>
                        <ProfileInspection
                            pieces={pieces}
                            profileInfo={profileInfo}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth" style={{ backgroundColor: 'white' }} transition={false}>
                        <ProfileStats
                            id={id}
                            pieces={pieces}
                            profileInfo={profileInfo}
                            setProfileInfo={setProfileInfo}
                        />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" transition={false}>
                        <CuttingPlans id={id} cps={cps} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third" transition={false}>
                        <ProjectDetails
                            projectCreationTime={projectCreationTime}
                            id={id}
                            name={name}
                            theoreticalWeight={projectTheoreticalWeight}
                            theoreticalLength={projectTheoreticalLength}
                            user={projectUser}
                            uploadedFileType={projectUploadedFileType}
                            activated={projectActivated}
                            priceCalculationMethod={priceCalculationMethod}
                            unitPrice={unitPrice}
                            unitDiscount={unitDiscount}
                            unitPriceType={unitPriceType}
                            purchasable={purchasable}
                            userType={userType}
                            ownProject={ownProject}
                        />
                    </Tab.Pane>
                </Tab.Content>

            </Tab.Container>
        </>
    )

}

export default MetalProfileProjectInfoSet;