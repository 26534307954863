import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useCpInfo = (id, result, userType) => {
    const { i18n, t } = useTranslation();

    const [checkedDiameter, setCheckedDiameter] = useState(-1);
    const [checkedFloors, setCheckedFloors] = useState([]);

    const [floors, setFloors] = useState([]);
    const [diameters, setDiameters] = useState([]);
    const [logLength, setLogLength] = useState(1200)
    const [showLowerFloorPatterns, setShowLowerFloorPatterns] = useState(true)
    const [showLowerFloorCheck, setShowLowerFloorCheck] = useState(false)

    const [resultOptions, setResultOptions] = useState({
        cpName: "",
        projectName: "",
        projectId: "",
        projectUser: "",
        floors: "",
        diameters: "",
        projectOverlapRatio: 0,
        minOutageLength: 0,
        solveFloorsSeparately: false,
        creationTime: "",
        totalExecutionTime: 0,
        diameterOptions: [],
    })

    const [currentResultSummary, setCurrentResultSummary] = useState({
        totalLogs: 0,
        totalWeight: 0,
        orderLogs: 0,
        orderWeight: 0,
        outageWeight: 0,
        wastedWeight: 0,
        yieldPercentage: 0,
        wastePercentage: 0,
        subWeight: 0,
    })

    const [checkedPatterns, setCheckedPatterns] = useState({})
    const [currentPatterns, setCurrentPatterns] = useState([])
    const [showedPatterns, setShowedPatterns] = useState([])

    const [currentSubstitutes, setCurrentSubstitutes] = useState([])
    const [currentWastes, setCurrentWastes] = useState([])

    const [isComponentLoading, setIsComponentLoading] = useState(true)

    const calculateWeight = (diameter, length) => {
        const density = 7.85
        const radius = diameter / 2 * 1e-1
        const A = Math.PI * radius ** 2
        const V = A * length
        const mass = V * density
        return mass * 1e-3
    }

    function hasCommonElement(list1, list2) {
        let set1 = new Set(list1);
        for (let i = 0; i < list2.length; i++) {
            if (set1.has(list2[i])) {
                return true;
            }
        }
        return false;
    }

    const getPatternInfo = (patterns, diameter, calculatedFloors, minOutageLength, version) => {

        let totalLogs = 0
        let totalWeight
        let orderLogs = 0
        let orderWeight
        let outageWeight // elde kalan fire
        let wastedWeight // teorik bütün fire
        let lowerSubstitutionWeight
        let yieldPercentage
        let wastePercentage

        let usedLength = 0
        let utilizedLength = 0
        let wastedLength = 0


        let outageLength = 0
        let lowerSubstitutionLength = 0

        for (let i = 0; i < patterns.length; i++) {
            let opLength = 0
            const pattern = patterns[i]
            const nLogs = pattern.nLogs
            totalLogs += nLogs
            const waste = pattern.waste
            let patternFloors = [...new Set(pattern.cuts.flatMap(item => item.floors))].map(Number);

            if (!hasCommonElement(calculatedFloors, patternFloors)) {
                orderLogs += nLogs

                if (waste >= minOutageLength) {
                    outageLength += nLogs * waste
                }
                wastedLength += nLogs * waste

                if (version === 1 && "overproductions" in pattern) {
                    let ops = pattern.overproductions
                    opLength += ops.reduce((sum, op) => sum + op.nCuts * op.cutLength, 0)
                    if (opLength >= minOutageLength) {
                        outageLength += nLogs * opLength
                    }
                    wastedLength += nLogs * opLength
                }

                if ("substitutions" in pattern) {
                    let subs = pattern.substitutions
                    lowerSubstitutionLength += subs.reduce((sum, sub) => sum + sub.number * sub.subs_by, 0)
                }
            }
        }

        if (version === 1) {
            outageLength -= lowerSubstitutionLength
        }

        const totalLength = totalLogs * logLength
        totalWeight = calculateWeight(diameter, totalLength).toFixed(0)

        const orderLength = orderLogs * logLength
        orderWeight = calculateWeight(diameter, orderLength).toFixed(0)

        outageWeight = calculateWeight(diameter, outageLength).toFixed(0)

        wastedWeight = calculateWeight(diameter, wastedLength).toFixed(0)

        lowerSubstitutionWeight = calculateWeight(diameter, lowerSubstitutionLength).toFixed(0)

        usedLength = orderLength - outageLength // kullanılan

        utilizedLength = orderLength - wastedLength// teorik fire harici

        if (version === 1) {
            utilizedLength += lowerSubstitutionLength
        }

        yieldPercentage = (100 * (utilizedLength / orderLength)).toFixed(2)
        wastePercentage = (100 - 100 * (utilizedLength / orderLength)).toFixed(2)
        return {
            totalLogs: totalLogs,
            totalWeight: totalWeight,
            orderLogs: orderLogs,
            orderWeight: orderWeight,
            outageWeight: outageWeight,
            wastedWeight: wastedWeight,
            lowerSubstitutionWeight: lowerSubstitutionWeight,
            yieldPercentage: yieldPercentage,
            wastePercentage: wastePercentage
        }
    }

    const getCurrentSubstitutesWithFloors = (_currentPatterns, _currentDiameter) => {
        let _currentSubstitutes = [];

        for (let i = 0; i < _currentPatterns.length; i++) {
            let pattern = _currentPatterns[i]
            if ("substitutions" in pattern) {
                let subs = pattern["substitutions"].map((sub) => (
                    {
                        subs_by: `${_currentDiameter}-${sub.subs_by}`,
                        actual: sub.actual,
                        nCuts: sub.number,
                        patternNo: pattern.patternNo,
                    }
                ))
                _currentSubstitutes = _currentSubstitutes.concat(subs)
            }
        }
        return _currentSubstitutes;
    }

    const getCurrentWastes = (_currentPatterns, _currentSubstitutes) => {
        let _currentWastes = {};

        for (let i = 0; i < _currentPatterns.length; i++) {
            let pattern = _currentPatterns[i]

            let waste = pattern.waste
            let patternNo = pattern.patternNo
            let nLogs = pattern.nLogs

            if (waste != 0) {
                if (waste in _currentWastes) {
                    _currentWastes[waste].nCuts += nLogs
                    _currentWastes[waste].patterns[patternNo] = nLogs

                } else {
                    _currentWastes[waste] = {
                        nCuts: nLogs,
                        patterns: {
                            [patternNo]: nLogs
                        }
                    }
                }
            }

            if ("overproductions" in pattern) {
                let ops = pattern.overproductions
                for (let j = 0; j < ops.length; j++) {
                    let op = ops[j]
                    if (op.cutLength in _currentWastes) {
                        _currentWastes[op.cutLength].nCuts += op.nCuts * nLogs
                        _currentWastes[op.cutLength].patterns[patternNo] = op.nCuts * nLogs
                    } else {
                        _currentWastes[op.cutLength] = {
                            nCuts: op.nCuts * nLogs,
                            patterns: {
                                [patternNo]: op.nCuts * nLogs
                            }
                        }
                    }
                }
            }

            if ("substitutions" in pattern) {
                let subs = pattern.substitutions
                for (let j = 0; j < subs.length; j++) {
                    let sub = subs[j]
                    _currentWastes[sub.subs_by].nCuts -= sub.number
                    _currentWastes[sub.subs_by].patterns[patternNo] -= sub.number

                    if (_currentWastes[sub.subs_by].patterns[patternNo] === 0) {
                        delete _currentWastes[sub.subs_by].patterns[patternNo]
                    }
                    if (_currentWastes[sub.subs_by].nCuts === 0) {
                        delete _currentWastes[sub.subs_by]
                    }
                }
            }
        }

        return _currentWastes;
    }

    const getCurrentResultsWithFloors = (checkedFloors, result, calculatedFloors, _showLowerFloorPatterns) => {

        const diameterResults = result.diameterResults;
        let _checkedPatterns = {}
        for (const diameter in diameterResults) {
            _checkedPatterns[diameter] = {
                totalLogs: 0,
                totalWeight: 0,
                orderLogs: 0,
                orderWeight: 0,
                outageWeight: 0,
                wastedWeight: 0,
                yieldPercentage: 0,
                wastePercentage: 0,
                patterns: [],
            }
            const diameterResult = diameterResults[diameter]
            const patterns = diameterResult.patterns

            for (let i = 0; i < patterns.length; i++) {
                let pattern = patterns[i]
                let patternFloors = [...new Set(pattern.cuts.flatMap(item => item.floors))].map(Number);
                if (patternFloors.some(item => checkedFloors.includes(item))) {
                    if (!hasCommonElement(calculatedFloors, patternFloors) || _showLowerFloorPatterns) {
                        _checkedPatterns[diameter]["patterns"].push(pattern)
                    }
                }
            }
            const patternInfo = getPatternInfo(_checkedPatterns[diameter]["patterns"], diameter, calculatedFloors, result.minOutageLength, result.version)
            for (const key in patternInfo) {
                _checkedPatterns[diameter][key] = patternInfo[key]
            }
        }
        return _checkedPatterns;
    }

    useEffect(() => {

        setLogLength(result.logLength)

        if (!(result.activated || [4, 5].includes(userType))) {
            setFloors(result.floors)
            setDiameters(result.diameters)
        }
        else {

            const rawDiameters = Object.keys(result.diameterResults).sort((a, b) => b - a)
            const _diameters = rawDiameters.map(item => {
                return { "label": `Φ${item}`, "value": item }
            })
            setDiameters(_diameters)
            const cDiameter = rawDiameters[0]
            setCheckedDiameter(cDiameter)

            let _checkedPatterns;

            let _currentSubstitutes = []

            const rawSelectedFloors = result.selectedFloors
            const allFloors = result.allFloors
            const _floors = rawSelectedFloors.map(item => {
                return { "label": item, "value": allFloors.indexOf(item) }
            })
            setFloors(_floors)

            const cFloors = [_floors[0].value]

            cFloors.sort((a, b) => a - b)

            setCheckedFloors(cFloors)

            _checkedPatterns = getCurrentResultsWithFloors(cFloors, result)

            _currentSubstitutes = getCurrentSubstitutesWithFloors(_checkedPatterns[cDiameter].patterns, cDiameter)


            setCheckedPatterns(_checkedPatterns)
            setCurrentPatterns(_checkedPatterns[cDiameter].patterns)
            setShowedPatterns(_checkedPatterns[cDiameter].patterns)

            setCurrentSubstitutes(_currentSubstitutes)
            setCurrentWastes(getCurrentWastes(_checkedPatterns[cDiameter].patterns, _currentSubstitutes))

            setResultOptions({

                cpName: `${result.cpName} (${result.cpId})`,
                projectName: result.projectName,
                projectId: result.projectId,
                projectUser: `${result.projectUser.email} (${result.projectUser.id})`,
                floors: result.selectedFloors.join(", "),
                diameters: Object.keys(result.diameterResults).map(item => `Φ${item}`).join(", "),
                projectOverlapRatio: result.bindirmeOrani,
                minOutageLength: result.minOutageLength,
                solveFloorsSeparately: result.solveFloorsSeparately ? t(t("Evet")) : t(t("Hayır")),
                creationTime: new Date(result.creationTime).toLocaleString(i18n.language),
                totalExecutionTime: Object.keys(result.diameterResults).map(diameter => result.diameterResults[diameter].executionTime).reduce((a, b) => a + b, 0).toFixed(2),
                diameterOptions:
                    Object.keys(result.diameterResults).map(
                        diameter => ({
                            diameter: `Φ${diameter}`,
                            executionTime: result.diameterResults[diameter].executionTime.toFixed(2),
                            use_substitutes: result.diameterResults[diameter].use_substitutes ? t(t("Evet")) : t(t("Hayır")),
                            use_partitions: result.diameterResults[diameter].use_partitions ? t(t("Evet")) : t(t("Hayır")),
                        })
                    ),
            })

            setCurrentResultSummary({
                totalLogs: _checkedPatterns[cDiameter].totalLogs,
                totalWeight: _checkedPatterns[cDiameter].totalWeight,
                orderLogs: _checkedPatterns[cDiameter].orderLogs,
                orderWeight: _checkedPatterns[cDiameter].orderWeight,
                outageWeight: _checkedPatterns[cDiameter].outageWeight,
                wastedWeight: _checkedPatterns[cDiameter].wastedWeight,
                yieldPercentage: _checkedPatterns[cDiameter].yieldPercentage,
                wastePercentage: _checkedPatterns[cDiameter].wastePercentage,
                subWeight: _checkedPatterns[cDiameter].lowerSubstitutionWeight,
            })
        }
        setIsComponentLoading(false)

    }, []);


    const handleDiametersChange = (checkedItems) => {
        const cDiameter = checkedItems[0]
        setCheckedDiameter(cDiameter);
        setCurrentPatterns(checkedPatterns[cDiameter].patterns)
        setShowedPatterns(checkedPatterns[cDiameter].patterns)

        let currentSubs = [];

        currentSubs = getCurrentSubstitutesWithFloors(checkedPatterns[cDiameter].patterns, cDiameter)

        setCurrentSubstitutes(currentSubs)
        setCurrentWastes(getCurrentWastes(checkedPatterns[cDiameter].patterns, currentSubs))

        setCurrentResultSummary({
            totalLogs: checkedPatterns[cDiameter].totalLogs,
            totalWeight: checkedPatterns[cDiameter].totalWeight,
            orderLogs: checkedPatterns[cDiameter].orderLogs,
            orderWeight: checkedPatterns[cDiameter].orderWeight,
            outageWeight: checkedPatterns[cDiameter].outageWeight,
            wastedWeight: checkedPatterns[cDiameter].wastedWeight,
            yieldPercentage: checkedPatterns[cDiameter].yieldPercentage,
            wastePercentage: checkedPatterns[cDiameter].wastePercentage,
            subWeight: checkedPatterns[cDiameter].lowerSubstitutionWeight,
        })

    };

    const handleFloorsChange = (checkedItems) => {
        checkedItems.sort((a, b) => a - b)
        setCheckedFloors(checkedItems);

        const minSelectedFloor = Math.min(...checkedItems)

        let calculatedFloors = []
        for (let i = 0; i < minSelectedFloor; i++) {
            calculatedFloors.push(i)
        }

        const _checkedPatterns = getCurrentResultsWithFloors(checkedItems, result, calculatedFloors, showLowerFloorPatterns)
        setCheckedPatterns(_checkedPatterns)
        setCurrentPatterns(_checkedPatterns[checkedDiameter].patterns)
        setShowedPatterns(_checkedPatterns[checkedDiameter].patterns)

        let currentSubs = getCurrentSubstitutesWithFloors(_checkedPatterns[checkedDiameter].patterns, checkedDiameter)

        setCurrentSubstitutes(currentSubs)
        setCurrentWastes(getCurrentWastes(_checkedPatterns[checkedDiameter].patterns, currentSubs))

        setCurrentResultSummary({
            totalLogs: _checkedPatterns[checkedDiameter].totalLogs,
            totalWeight: _checkedPatterns[checkedDiameter].totalWeight,
            orderLogs: _checkedPatterns[checkedDiameter].orderLogs,
            orderWeight: _checkedPatterns[checkedDiameter].orderWeight,
            outageWeight: _checkedPatterns[checkedDiameter].outageWeight,
            wastedWeight: _checkedPatterns[checkedDiameter].wastedWeight,
            yieldPercentage: _checkedPatterns[checkedDiameter].yieldPercentage,
            wastePercentage: _checkedPatterns[checkedDiameter].wastePercentage,
            subWeight: _checkedPatterns[checkedDiameter].lowerSubstitutionWeight,
        })

        let minFloor = Math.min(...floors.map((item) => item.value))
        if (checkedItems.includes(minFloor)) {
            setShowLowerFloorCheck(false)
        } else {
            setShowLowerFloorCheck(true)
        }

    };

    const handleShowLowerFloorPatterns = (_showLowerFloorPatterns) => {
        setShowLowerFloorPatterns(_showLowerFloorPatterns)

        const minSelectedFloor = Math.min(...checkedFloors)

        let calculatedFloors = []
        for (let i = 0; i < minSelectedFloor; i++) {
            calculatedFloors.push(i)
        }

        const _checkedPatterns = getCurrentResultsWithFloors(checkedFloors, result, calculatedFloors, _showLowerFloorPatterns)
        setCheckedPatterns(_checkedPatterns)
        // setCurrentPatterns(_checkedPatterns[checkedDiameter].patterns)
        setShowedPatterns(_checkedPatterns[checkedDiameter].patterns)

        let currentSubs = getCurrentSubstitutesWithFloors(_checkedPatterns[checkedDiameter].patterns, checkedDiameter)

        setCurrentSubstitutes(currentSubs)
        setCurrentWastes(getCurrentWastes(_checkedPatterns[checkedDiameter].patterns, currentSubs))

    }

    return {
        isComponentLoading,

        showLowerFloorCheck,

        diameters,
        floors,
        checkedDiameter,
        checkedFloors,
        currentPatterns,
        showedPatterns,

        currentSubstitutes,
        currentWastes,

        resultOptions,
        currentResultSummary,

        handleDiametersChange,
        handleFloorsChange,
        handleShowLowerFloorPatterns,
    }
}

export default useCpInfo;