import AppNavbarLocales from '../components/AppNavbar/locales/en.json'
import LandingPageLocales from '../pages/LandingPage/locales/en.json'
import FooterLocales from '../components/Footer/locales/en.json'
import LoginLocales from '../pages/Login/locales/en.json'
import SignupLocales from '../pages/Signup/locales/en.json'
import ProjectsLocales from '../pages/Projects/locales/en.json'
import ProjectRowLocales from '../components/ProjectRow/locales/en.json'
import ProjectInfoLocales from '../pages/ProjectInfo/locales/en.json'
import RebarTableLocales from '../components/RebarTable/locales/en.json'
import WeightTableLocales from '../components/WeightTable/locales/en.json'
import CheckboxListLocales from '../components/CheckboxList/locales/en.json'
import LongRebarTableLocales from '../components/LongRebarTable/locales/en.json'
import ProjectCreationLocales from '../pages/ProjectCreationNew/locales/en.json'
import useLoginLocales from '../hooks/useLogin/locales/en.json'
import useProjectCreationLocales from '../hooks/useProjectCreation/locales/en.json'
import CpCreationLocales from '../pages/CpCreation/locales/en.json'
import CpInfoLocales from '../pages/CpInfo/locales/en.json'
import PatternLocales from '../components/Pattern/locales/en.json'
import CpInfoTablesLocales from '../components/CpInfoTables/locales/en.json'
import useCpInfoLocales from '../hooks/useCpInfo/locales/en.json'
import useCpCreationLocales from '../hooks/useCpCreation/locales/en.json'
import AccountLocales from '../pages/Account/locales/en.json'
import useAccountSettingsLocales from '../hooks/useAccountSettings/locales/en.json'
import ErrorLocales from '../pages/Error/locales/en.json'
import ForgotPasswordLocales from '../pages/ForgotPassword/locales/en.json'
import UnderConstructionLocales from '../pages/UnderConstruction/locales/en.json'
import UsersPurchasesLocales from '../pages/UsersPurchases/locales/en.json'
import VerificationLocales from '../pages/Verification/locales/en.json'
import useVerificationLocales from '../hooks/useVerification/locales/en.json'
import useSignUpLocales from '../hooks/useSignUp/locales/en.json'
import useDelegatePanelLocales from '../hooks/useDelegatePanel/locales/en.json'
import DelegatePanelLocales from '../pages/DelegatePanel/locales/en.json'
import DelegationContractLocales from '../pages/DelegationContract/locales/en.json'
import ProjectPurchaseLocales from '../pages/ProjectPurchase/locales/en.json'
import useProjectPurchaseLocales from '../hooks/useProjectPurchase/locales/en.json'
import SaleContractLocales from '../pages/SaleContract/locales/en.json'
import useEmailChangeLocales from '../hooks/useEmailChange/locales/en.json'
import useProjectInfoLocales from '../hooks/useProjectInfo/locales/en.json'
import useProjectsLocales from '../hooks/useProjects/locales/en.json'
import useResetPasswordLocales from '../hooks/useResetPassword/locales/en.json'
import useSendResetPwLinkLocales from '../hooks/useSendResetPwLink/locales/en.json'

export const en_lang_dict = Object.assign(
    {},
    AppNavbarLocales,
    LandingPageLocales,
    FooterLocales,
    LoginLocales,
    SignupLocales,
    ProjectsLocales,
    ProjectRowLocales,
    ProjectInfoLocales,
    RebarTableLocales,
    WeightTableLocales,
    CheckboxListLocales,
    LongRebarTableLocales,
    ProjectCreationLocales,
    useLoginLocales,
    useProjectCreationLocales,
    CpCreationLocales,
    CpInfoLocales,
    PatternLocales,
    CpInfoTablesLocales,
    useCpInfoLocales,
    useCpCreationLocales,
    AccountLocales,
    useAccountSettingsLocales,
    ErrorLocales,
    ForgotPasswordLocales,
    UnderConstructionLocales,
    UsersPurchasesLocales,
    VerificationLocales,
    useVerificationLocales,
    useSignUpLocales,
    useDelegatePanelLocales,
    DelegatePanelLocales,
    DelegationContractLocales,
    ProjectPurchaseLocales,
    useProjectPurchaseLocales,
    SaleContractLocales,
    useEmailChangeLocales,
    useProjectInfoLocales,
    useProjectsLocales,
    useResetPasswordLocales,
    useSendResetPwLinkLocales
)
