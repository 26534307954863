import { useState, useEffect } from 'react';
import { getUserData } from '../../services/userService';
import { getAdditionalUserInfo, setAdditionalUserInfo } from '../../services/additionalUserInfo';
import { getProjectPrice } from '../../services/getProjectPrice';
import { sendProjectCreditCardPayment } from '../../services/paymentServices/sendProjectCreditCardPaymentService';
import { isProjectPurchasable } from '../../services/paymentServices/projectPurchasableService';

import troyLogo from '../../img/creditcard/troy-logo.png'
import masterCardLogo from '../../img/creditcard/mastercard-logo.png'
import visaLogo from '../../img/creditcard/visa-logo.png'
import { sendProjectTransferPayment } from '../../services/paymentServices/sendProjectTransferPaymentService';
import { useNavigate } from 'react-router-dom';

import { iso31661_country_codes } from '../../other/variables';

import { useTranslation } from 'react-i18next';

const useProjectPurchase = (id) => {

    const { i18n, t } = useTranslation();

    const [isLoading, setIsLoading] = useState(true)
    const [step, setStep] = useState(1)
    const [email, setEmail] = useState(``)
    const [invoiceInfo, setInvoiceInfo] = useState({})
    const [saveInvoiceInfo, setSaveInvoiceInfo] = useState(false)

    const [telephoneError, setTelephoneError] = useState(``)
    const [countryError, setCountryError] = useState(``)
    const [provinceError, setProvinceError] = useState(``)

    const [projectName, setProjectName] = useState(``)
    const [projectTheoreticalWeight, setProjectTheoreticalWeight] = useState(0)
    const [unitPrice, setUnitPrice] = useState(0)
    const [unitPriceType, setUnitPriceType] = useState(``)
    const [unitDiscount, setUnitDiscount] = useState(0)

    const [totalPrice, setTotalPrice] = useState(0)
    const [initialPrice, setInitialPrice] = useState(0)

    const [vat, setVat] = useState(0)

    const [saleContract, setSaleContract] = useState(``)
    const [saleContractApproved, setSaleContractApproved] = useState(false)
    const [paymentMethod, setPaymentMethod] = useState(1) // 1 => transfer, 2 => credit card

    const [orderLoading, setOrderLoading] = useState(false)
    const [orderId, setOrderId] = useState(-1)

    const [orderDate, setOrderDate] = useState(``)


    const [cardHolder, setCardHolder] = useState(``)
    const [cardHolderError, setCardHolderError] = useState(``)

    const [cardNumber, setCardNumber] = useState(``)
    const [cardType, setCardType] = useState(`Unknown`);
    const [cardTypeLogo, setCardTypeLogo] = useState(``);
    const [cardError, setCardError] = useState(``)

    const [expirationDate, setExpirationDate] = useState(``)
    const [expDateError, setExpDateError] = useState(``);

    const [cvv, setCvv] = useState(``)
    const [cvvError, setCvvError] = useState(``)

    const [discountRate, setDiscountRate] = useState(0)

    useEffect(() => {
        async function fetchData() {
            let userData;
            try {
                userData = await getUserData();
            }
            catch {
                // getUserError
            }
            if (userData.user_type === 3 && !userData.delegation_active) {
                navigate(`/${i18n.language}/delegation-contract`)
            }
            const user_type = userData.user_type;
            if (user_type === 0) {
                window.location = `/${i18n.language}/inactive-account`
            }
            setEmail(userData.email)
            let projectPriceResponse;
            try {
                projectPriceResponse = await getProjectPrice(id);
            }
            catch {
                var props = {
                    error_code: `9GYTYS`,
                    detail: t(`Sistem Hatası. Sistem yöneticisi ile irtibata geçin.`),
                }

                navigate(`/error`, { state: { props: props } })
            }
            const projectPriceData = await projectPriceResponse.json();

            if (projectPriceData.activated) {
                window.location = `/${i18n.language}/project-info/${id}`
            }

            const discount = projectPriceData.transfer_discount

            setProjectName(projectPriceData.project_name)
            document.title = `${t("Satın Al:")} ${projectPriceData.project_name}`
            setProjectTheoreticalWeight(projectPriceData.theoretical_weight)
            setUnitPrice(projectPriceData.unit_price)
            setUnitDiscount(projectPriceData.unit_discount)
            setUnitPriceType(projectPriceData.unit_price_type)
            setDiscountRate(discount)
            setTotalPrice(Math.floor((1 - discount) * projectPriceData.total_price))
            setInitialPrice(projectPriceData.total_price)
            setVat(projectPriceData.vat)

            let isPurchasableResponse;
            try {
                isPurchasableResponse = await isProjectPurchasable(id)
            }
            catch {
                var props = {
                    error_code: `CWLEUT`,
                    detail: t(`Sistem Hatası. Sistem yöneticisi ile irtibata geçin.`),
                }

                navigate(`/error`, { state: { props: props } })
            }
            const isPurchasableData = await isPurchasableResponse.json()

            if (!isPurchasableData.purchasable) {
                if (isPurchasableData.detail === `already purchased`) {
                    window.location = `/${i18n.language}/project-info/${id}`
                }
                else {

                    const pmethod = isPurchasableData.payment_info.payment_method === `transfer` ? 1 : 2
                    setInvoiceInfo({
                        type: isPurchasableData.payment_info.invoice_info.type,
                        data: {
                            ...isPurchasableData.payment_info.invoice_info.data,
                            email: userData.email,
                        }
                    })

                    setPaymentMethod(pmethod)
                    setOrderId(isPurchasableData.payment_info.order_id)
                    setOrderDate(isPurchasableData.payment_info.order_date)

                    setStep(4)
                    setIsLoading(false)
                    return
                }
            }
            let additionalUserInfoResponse;
            try {
                additionalUserInfoResponse = await getAdditionalUserInfo();
            }
            catch {
                var props = {
                    error_code: `14S2AF`,
                    detail: t(`Sistem Hatası. Sistem yöneticisi ile irtibata geçin.`),
                }

                navigate(`/error`, { state: { props: props } })
            }
            if (!(additionalUserInfoResponse.ok)) {
                var props = {
                    error_code: `WA20KV`,
                    detail: `${t("Kullanıcı bilgileri yüklenemedi:")} ${additionalUserInfoResponse.statusText} (${additionalUserInfoResponse.status})`,
                }

                navigate(`/error`, { state: { props: props } })
            }
            const additionalUserInfoData = await additionalUserInfoResponse.json()
            let country = additionalUserInfoData.country;
            let telephone_country_code = additionalUserInfoData.telephone_country_code;
            // default country is türkiye
            if (country === null || country === ``) {
                country = `224`;
            }
            if (telephone_country_code === null || telephone_country_code === ``) {
                telephone_country_code = 90;
            }
            setInvoiceInfo({
                type: `real_person`,
                data: {
                    ...additionalUserInfoData,
                    email: userData.email,
                    country: country,
                    telephone_country_code: telephone_country_code,
                }
            })

            setIsLoading(false)
        }

        fetchData();
    }, []);

    const handleInvoiceInfoStep = (e) => {
        e.preventDefault();
        let proceed = true;
        if (invoiceInfo.data.telephone_country_code === null || invoiceInfo.data.telephone_country_code === ``) {
            setInvoiceInfo({ ...invoiceInfo, data: { ...invoiceInfo.data, telephone_country_code: 90 } })
        }
        if (invoiceInfo.data.telephone === null || invoiceInfo.data.telephone === ``) {
            setTelephoneError(t("Telefon numarasını yazınız."))
            proceed = false;
        }
        else {
            const telephoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
            const validNumber = telephoneRegex.test(invoiceInfo.data.telephone)
            if (!validNumber) {
                setTelephoneError(t("Geçerli bir numara yazınız."))
                proceed = false
            }
            else {
                setTelephoneError(``)
            }
        }
        if (invoiceInfo.data.country === null || invoiceInfo.data.country === `` || !Object.keys(iso31661_country_codes).includes(invoiceInfo.data.country)) {
            setCountryError(t("Ülke seçiniz."))
            proceed = false;
        }
        else {
            setCountryError(``)
            if (invoiceInfo.data.province === null || invoiceInfo.data.province === ``) {
                setProvinceError(t("Şehir/Eyalet seçiniz."))
                proceed = false;
            }
            else {
                setProvinceError(``)
            }
        }

        if (proceed) {
            setStep(2)
        }
    }

    const handleChangePaymentMethod = (e) => {
        setCardHolder(``)
        setCardNumber(``)
        setExpirationDate(``)
        setCardType(`Unknown`)
        setCardTypeLogo(``)
        setCvv(``)

        setCardHolderError(``)
        setExpDateError(``)
        setCardError(``)
        setCvvError(``)
        setPaymentMethod(Number(e.target.value));
        if (Number(e.target.value) === 1) {
            const newPrice = Math.floor((1 - discountRate) * totalPrice)
            setTotalPrice(newPrice)
        }
        else {
            setTotalPrice(initialPrice)
        }
    }

    // Luhn Algoritması
    function luhnCheck(cardNumber) {
        let sum = 0;
        let shouldDouble = false;
        for (let i = cardNumber.length - 1; i >= 0; i--) {
            let digit = parseInt(cardNumber.charAt(i));

            if (shouldDouble) {
                digit *= 2;
                if (digit > 9) {
                    digit -= 9;
                }
            }

            sum += digit;
            shouldDouble = !shouldDouble;
        }

        return sum % 10 === 0;
    }

    // Kart Tipini Belirleme
    function getCardType(cardNumber) {
        if (cardNumber.startsWith(`4`)) {
            return `VISA`;
        } else if (/^5[1-5]/.test(cardNumber) || /^2[2-7]/.test(cardNumber)) {
            return `MasterCard`;
        } else if (/^9792/.test(cardNumber) || /^65/.test(cardNumber)) {
            return `TROY`;
        } else {
            return `Unknown`;
        }
    }

    function formatCardNumber(value) {
        return value
            .replace(/\s?/g, '') // Önce mevcut boşlukları temizle
            .replace(/(\d{4})/g, '$1 ') // Her 4 hane sonrası boşluk ekle
            .trim() // Başta ve sonda kalan boşlukları kaldır
            .substring(0, 19); // Maksimum 16 rakam + 3 boşluk olacak şekilde sınırla
    }

    const handleCardNumberChange = (e) => {
        const formattedNumber = formatCardNumber(e.target.value);
        setCardNumber(formattedNumber);

        let _cardType = getCardType(formattedNumber.replace(/\s/g, ''))

        setCardType(_cardType);
        if (_cardType === `MasterCard`) {
            setCardTypeLogo(masterCardLogo)
        } else if (_cardType === `VISA`) {
            setCardTypeLogo(visaLogo)
        } else if (_cardType === `TROY`) {
            setCardTypeLogo(troyLogo)
        }
    }

    const handleKeyDown = (e) => {
        if (!/[\d\s]/.test(e.key) &&
            e.key !== 'Backspace' &&
            e.key !== 'Tab' &&
            e.key !== 'Escape' &&
            e.key !== 'Enter' &&
            e.key !== 'ArrowLeft' &&
            e.key !== 'ArrowRight') {
            e.preventDefault();
        }
    };

    function formatExpirationDate(value) {
        const cleanValue = value.replace(/[^\d]/g, ''); // Sadece rakamları al
        if (cleanValue.length <= 2) {
            return cleanValue;
        }
        return `${cleanValue.slice(0, 2)}/${cleanValue.slice(2, 4)}`;
    }

    function isValidExpirationDate(value) {
        const currentYear = new Date().getFullYear() % 100; // YY formatında
        const currentMonth = new Date().getMonth() + 1; // Ay 0'dan başlar
        const [expMonth, expYear] = value.split('/').map(Number);

        if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
            return false;
        }
        return expMonth >= 1 && expMonth <= 12;
    }

    const handleExpirationDateChange = (e) => {
        const formattedDate = formatExpirationDate(e.target.value);
        setExpirationDate(formattedDate);
    };

    const handleSubmitOnStepTwo = (e, saleContractHtml) => {
        e.preventDefault();

        let halt = false;

        if (paymentMethod === 2) {
            // Numara tam ve geçerliyse kontrol et
            if (cardNumber.replace(/\s/g, '').length === 16) {
                if (luhnCheck(cardNumber.replace(/\s/g, ''))) {
                    if (cardType === `Unknown`) {
                        setCardError(t("Bilinmeyen kart tipi. Sadece MasterCard, VISA ve TROY kartları kabul edilir."))
                        halt = true
                    }
                    else {
                        setCardError(``);
                    }
                }
                else {
                    setCardError(t("Geçersiz kart numarası."));
                    halt = true;
                }
            } else {
                setCardError(t("Eksik kart numarası."))
                halt = true;
            }

            if (cardHolder.length < 3) {
                setCardHolderError(t("Geçersiz isim."))
                halt = true
            } else {
                setCardHolderError("")
            }

            if (!isValidExpirationDate(expirationDate)) {
                setExpDateError(t("Geçersiz son kullanma tarihi."));
                halt = true
            } else {
                setExpDateError("");
            }

            if (cvv.length !== 3) {
                setCvvError(t("Geçersiz CVV."))
                halt = true
            } else {
                setCvvError("")
            }
        }

        if (!halt) {
            setStep(3);
            setSaleContract(saleContractHtml)
        }
    }

    const navigate = useNavigate()

    const handleSubmit = async () => {

        setOrderLoading(true);

        if (saveInvoiceInfo) {
            try {
                await setAdditionalUserInfo(invoiceInfo.data);
            }
            catch {
                var props = {
                    error_code: `VZ2ZWQ`,
                    detail: t("Sistem Hatası. Sistem yöneticisi ile irtibata geçin."),
                }
                navigate(`/error`, { state: { props: props } })
            }
        }

        if (paymentMethod === 1) {
            // Havale/EFT Odemesi
            try {
                const response = await sendProjectTransferPayment(
                    id,
                    `transfer`,
                    `project`,
                    invoiceInfo,
                    unitPrice,
                    totalPrice,
                    vat,
                    saleContract,
                    discountRate,
                )
                if (response.ok) {
                    const data = await response.json();
                    setOrderId(data.order_id)
                    setStep(4)
                    setOrderDate(new Date())
                    setOrderLoading(false)
                }
                else {
                    var props = {
                        error_code: `2WU433`,
                        detail: `${t("Sipariş alınamadı:")} ${response.statusText} (${response.status})`,
                    }

                    navigate(`/error`, { state: { props: props } })
                }
            }
            catch {
                var props = {
                    error_code: `HPLL0U`,
                    detail: t("Sistem Hatası: Sipariş alınamadı. Sistem yöneticisi ile irtibata geçin."),
                }


                navigate(`/error`, { state: { props: props } })
            }

        }
        else {
            // Kredi/Banka Kartı Ödemesi

            try {
                const response = await sendProjectCreditCardPayment(
                    id,
                    `credit_card`,
                    `project`,
                    invoiceInfo,
                    unitPrice,
                    totalPrice,
                    vat,
                    saleContract,
                    cardNumber.replaceAll(' ', ''),
                    cardHolder,
                    expirationDate.split(`/`)[0], // Month
                    expirationDate.split(`/`)[1], // Year
                    cvv,
                    cardType,
                )

                if (response.ok) {
                    const data = await response.json();
                    const htmlContent = data.html

                    navigate('/card-payment', { state: { htmlContent: htmlContent } })
                }
                else {
                    let data;
                    try {
                        data = await response.json();

                        navigate(`/error`, { state: { props: data } })
                    }
                    catch {
                        var props = {
                            error_code: `9K0CFR`,
                            detail: `${t("Ödeme alınamadı:")} ${response.statusText} (${response.status})`,
                        }

                        navigate(`/error`, { state: { props: props } })
                    }
                }
            }
            catch {
                var props = {
                    error_code: `4A0V1Y`,
                    detail: t("Sistem Hatası: Sipariş alınamadı. Sistem yöneticisi ile irtibata geçin."),
                }


                navigate(`/error`, { state: { props: props } })
            }

        }
    }

    return {
        isLoading,
        step,
        setStep,
        email,
        invoiceInfo,
        setInvoiceInfo,
        saveInvoiceInfo,
        setSaveInvoiceInfo,

        telephoneError,
        countryError,
        provinceError,
        handleInvoiceInfoStep,

        projectName,
        projectTheoreticalWeight,
        unitPrice,
        unitPriceType,
        unitDiscount,
        initialPrice,
        discount: discountRate,
        totalPrice,
        vat,
        paymentMethod,
        setPaymentMethod,

        saleContractApproved,
        setSaleContractApproved,
        setSaleContract,

        orderLoading,
        handleSubmit,
        orderId,
        orderDate,

        cardHolder,
        setCardHolder,
        cardNumber,
        cardType,
        cardTypeLogo,
        handleCardNumberChange,
        handleKeyDown,
        expirationDate,
        handleExpirationDateChange,
        cvv,
        setCvv,
        cardHolderError,
        cardError,
        expDateError,
        cvvError,

        handleChangePaymentMethod,

        handleSubmitOnStepTwo,
    }
};

export default useProjectPurchase;