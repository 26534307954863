import React, { useState } from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Collapse from 'react-bootstrap/Collapse';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';

import { useTranslation } from 'react-i18next';

const MetalProfileCpCreation = ({
    id,
    profileInfo,
    setProfileInfo,
    handleCpNameChange,
    handleSubmit
}) => {

    const { i18n, t } = useTranslation();

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    const [open, setOpen] = useState(true);

    return (
        <Container className="p-4 my-4 bg-white border" style={{
            minHeight: "max(70vh,600px)",
            minWidth: "80vw",
        }}>
            <Form onSubmit={(e) => { handleSubmit(e, profileInfo, id) }}>
                <Row className='my-3'>
                    <Col md={3} className='d-flex align-items-center mb-2'>
                        <span
                            style={{
                                fontSize: "1.5rem",
                                fontWeight: "bold",
                                paddingLeft: "1rem"
                            }}
                        >
                            {t("Kesim Planı ismi")}:
                        </span>
                    </Col>
                    <Col md={9} className='d-flex align-items-center'>
                        <Form.Control
                            required
                            type="text"
                            placeholder={t("Kesim Planı ismi giriniz")}
                            maxLength={25}
                            onChange={handleCpNameChange}
                            style={{
                                fontSize: "1.5rem",
                                borderRadius: "0"
                            }}
                        />
                    </Col>
                </Row>


                <Container className='p-2' style={{
                    border: "1px solid #ced4db",
                }}>
                    <Row className="align-items-center" onClick={() => setOpen(!open)}>
                        <Col lg={11} xs={10} className='m-0 ps-4'>
                            <span
                                style={{
                                    fontSize: "1.5rem",
                                    fontWeight: "bold"
                                }}
                            >
                                {t("Profil bilgileri")}
                            </span>
                        </Col>
                        <Col lg={1} xs={2}>
                            {open ? (
                                <FiChevronUp
                                    style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
                                />
                            ) : (
                                <FiChevronDown
                                    style={{ cursor: 'pointer', fontSize: '1.5rem', fontWeight: 'bold' }}
                                />
                            )}
                        </Col>
                    </Row>
                    <Collapse in={open}>
                        <div className="p-2 table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ width: "10%" }}>{t("Profil")}</th>
                                        <th scope="col" style={{ width: "10%" }}>{t("Malzeme")}</th>
                                        <th scope="col" style={{ width: "15%" }}>{t("Optimizasyona Dahil Edilsin mi?")}</th>
                                        <th scope="col" style={{ width: "20%" }}>{t("Kesim Yapılacak Boy Uzunluğu")} (mm)</th>
                                        <th scope="col" style={{ width: "10%" }}>{t("Testere kalınlığı")} (mm)</th>
                                    </tr>
                                    {/* line for changing all at once: */}
                                    <tr>
                                        <th colSpan={2} className="text-center">
                                        </th>
                                        <td>
                                            <Form.Select

                                                value={profileInfo[0].include_in_optimization}
                                                onChange={(e) => {
                                                    setProfileInfo(
                                                        profileInfo.map((item) => {
                                                            return {
                                                                ...item,
                                                                include_in_optimization: e.target.value == 'true'
                                                            }
                                                        })
                                                    )
                                                }}
                                            >
                                                <option value={true}>{t("Evet")}</option>
                                                <option value={false}>{t("Hayır")}</option>
                                            </Form.Select>
                                        </td>
                                        <td>
                                            <Form.Control

                                                type="number"
                                                value={profileInfo[0].stock_length}
                                                onChange={(e) => {
                                                    setProfileInfo(
                                                        profileInfo.map((item) => {
                                                            return {
                                                                ...item,
                                                                stock_length: e.target.value
                                                            }
                                                        })
                                                    )
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <Form.Control

                                                type="number"
                                                value={profileInfo[0].blade_thickness}
                                                onChange={(e) => {
                                                    setProfileInfo(
                                                        profileInfo.map((item) => {
                                                            return {
                                                                ...item,
                                                                blade_thickness: e.target.value
                                                            }
                                                        })
                                                    )
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {profileInfo.map((profile) => {
                                        return (
                                            <tr key={profile.group_id}>
                                                <td>{profile.profile}</td>
                                                <td>{profile.grade}</td>
                                                <td>
                                                    <Form.Select

                                                        value={profile.include_in_optimization}
                                                        onChange={(e) => {
                                                            setProfileInfo(
                                                                profileInfo.map((item) => {
                                                                    if (item.group_id === profile.group_id) {
                                                                        return {
                                                                            ...item,
                                                                            include_in_optimization: e.target.value
                                                                        }
                                                                    }
                                                                    return item;
                                                                })
                                                            )
                                                        }}
                                                    >
                                                        <option value={true}>{t("Evet")}</option>
                                                        <option value={false}>{t("Hayır")}</option>
                                                    </Form.Select>
                                                </td>
                                                <td>
                                                    <Form.Control

                                                        type="number"
                                                        value={profile.stock_length}
                                                        onChange={(e) => {
                                                            setProfileInfo(
                                                                profileInfo.map((item) => {
                                                                    if (item.group_id === profile.group_id) {
                                                                        return {
                                                                            ...item,
                                                                            stock_length: e.target.value
                                                                        }
                                                                    }
                                                                    return item;
                                                                })
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <Form.Control

                                                        type="number"
                                                        value={profile.blade_thickness}
                                                        onChange={(e) => {
                                                            setProfileInfo(
                                                                profileInfo.map((item) => {
                                                                    if (item.group_id === profile.group_id) {
                                                                        return {
                                                                            ...item,
                                                                            blade_thickness: e.target.value
                                                                        }
                                                                    }
                                                                    return item;
                                                                })
                                                            )
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Collapse>
                </Container>
                <div className='text-center mt-3'>
                    <Button variant="success" size="lg" type="submit">
                        {t("HESAPLA")}
                    </Button>
                </div>


            </Form>
        </Container>
    )
}

export default MetalProfileCpCreation;