import React from 'react';
import styles from './WasteTable.module.css'
import { useTranslation } from 'react-i18next';

const WasteTable = ({ wastes, ratio=1 }) => {

    let fontSize1 = `${12 * ratio}pt`

    const { t } = useTranslation();

    return (
        <div className={`mb-3 border ${styles.tableWrap}`} style={{ fontSize: `${fontSize1}` }}>
            <table className={`table table-sm text-center align-middle`}>
                <thead className={styles.tableHeader}>
                    <tr>
                        <th scope="col" style={{ width: "20%" }}>{t("Uzunluk")}</th>
                        <th scope="col" style={{ width: "20%" }}>{t("Adet")}</th>
                        <th scope="col" style={{ width: "20%" }}>{t("Profil")}</th>
                        <th scope="col" style={{ width: "40%", textAlign: "start" }}>{t("Kesim No.")}</th>
                    </tr>
                </thead>
                <tbody>
                    {wastes.map((waste,i) => {
                        
                        if(waste.quantity === 0){
                            return <></>
                        }

                        return <tr key={i}>
                            <td>{waste.length}</td>
                            <td>{waste.quantity}</td>
                            <td>{`${waste.profile} (${waste.grade})`}</td>
                            <td style={{ textAlign: "start" }}>{waste.patterns}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default WasteTable