import { useState, useCallback, useEffect } from 'react';
import { getProject } from '../../services/getProjectService';
import { createCp } from '../../services/cpService';
import { getUserData } from '../../services/userService';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { metalProfileInfoUpdate } from '../../services/metalProfileInfoUpdate';

const createFloorDict = (yerlesimPlani) => {
    function flattenDict(dict) {
        const result = {};

        for (const key in dict) {
            result[key] = [];
            helper(dict[key], result[key]);
        }

        return result;
    }

    function helper(node, arr) {
        if (Array.isArray(node)) {
            arr.push(...node);
        } else if (typeof node === 'object' && node !== null) {
            for (const key in node) {
                helper(node[key], arr);
            }
        }
    }
    return flattenDict(yerlesimPlani["Tümü"]);
}

const useCpCreation = (id) => {

    const { i18n, t } = useTranslation();

    const [projectName, setProjectName] = useState("")
    const [projectType, setProjectType] = useState("")

    // if projectType === 'rebar'
    const [diameters, setDiameters] = useState([])
    const [donatiDict, setDonatiDict] = useState({})
    const [allRebars, setAllRebars] = useState([])
    const [floors, setFloors] = useState([])
    const [floorRebarDict, setFloorRebarDict] = useState({})
    // else if projectType === 'metal_profile'
    const [profileInfo, setProfileInfo] = useState([])

    const [cpName, setCpName] = useState("")

    const [selectedFloors, setSelectedFloors] = useState([])
    const [selectedDiameters, setSelectedDiameters] = useState([])
    const [minBarLength, setMinBarLength] = useState(0)

    const [isLoading, setIsLoading] = useState(true)
    const [isCpLoading, setIsCpLoading] = useState(false)

    const [useSubstitutesChecked, setUseSubstitutesChecked] = useState(false)
    const [separeteFloorsChecked, setSepareteFloorsChecked] = useState(false)

    const [disableSeparateFloors, setDisableSeparateFloors] = useState(true)
    const [disableUseSubstitutes, setDisableUseSubstitutes] = useState(true)

    const [errorToast, setErrorToast] = useState({
        show: false,
        message: ''
    })

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {

            const user_data = await getUserData()
            if (user_data.user_type === 3 && !user_data.delegation_active) {
                navigate(`/${i18n.language}/delegation-contract`)
            }
            const user_type = user_data.user_type
            if (user_type === 0) {
                window.location = `/${i18n.language}/inactive-account`
            }
            else {
                let response;
                try {
                    response = await getProject(id);
                } catch (error) {
                    var props = {
                        error_code: "B90BCC",
                        detail: t(`Sistem Hatası. Sistem yöneticisi ile irtibata geçin.`),
                    }
                    navigate(`/error`, { state: { props: props } })
                }

                if (!response.ok) {
                    var props = {
                        error_code: "ZCD654",
                        detail: `${t("Proje yüklenemedi:")} ${response.statusText} (${response.status})`,
                    }

                    navigate(`/error`, { state: { props: props } })
                }

                const data = await response.json();

                setProjectName(data.name)

                setProjectType(data.projectType)

                if(data.projectType === 'rebar'){
                    data.caplar.sort((a, b) => a - b)
                    const rawDiameters = data.caplar;
                    const _diameters = rawDiameters.map(d => ({
                        "label": `Φ${d}`,
                        "value": `${d}`
                    }));
                    setDiameters(_diameters);
    
                    setSelectedDiameters([_diameters[0].value])
    
                    setDonatiDict(data.donatiDict);
    
                    setAllRebars(Object.keys(data.donatiDict));
    
                    const rawFloors = data.katlar
                    const _floors = rawFloors.map(f => ({
                        "label": f,
                        "value": f,
                    }));
                    setFloors(_floors)
                    const yerlesimPlani = data.yerlesimPlani
                    const _floorDict = createFloorDict(yerlesimPlani)
                    setFloorRebarDict(_floorDict)
                    setSelectedFloors([_floors[0].value])
                }
                else {
                    setProfileInfo(data.optimization_groups)
                }

                setIsLoading(false);

            }
        }

        fetchData();
    }, []);

    const createOptimizationInput = (rebars) => {
        let floorList;
        floorList = floors.map(item => item.label)
        let inputDonatiDict
        if (separeteFloorsChecked) {
            inputDonatiDict = Array.from({ length: floorList.length }, () => ({}));
        }
        else {
            inputDonatiDict = {}
        }
        for (let i = 0; i < rebars.length; i++) {
            const rebar = rebars[i]
            const [diameter, length] = rebar.split("-")
            if (!selectedDiameters.includes(diameter)) {
                continue
            }
            /////////////////////////////////////////////////
            if (separeteFloorsChecked) {
                const floorIdx = floorList.indexOf(donatiDict[rebar]["kat"])
                const inputDonati = inputDonatiDict[floorIdx][`${diameter}-${length}`]
                if (inputDonati) {
                    inputDonatiDict[floorIdx][`${diameter}-${length}`]["adet"] += donatiDict[rebar]["adet"]
                    inputDonatiDict[floorIdx][`${diameter}-${length}`]["elemanlar"][rebar] = donatiDict[rebar]["adet"]
                    if (inputDonatiDict[floorIdx][`${diameter}-${length}`]["donatiYerleri"][floorIdx]) {
                        inputDonatiDict[floorIdx][`${diameter}-${length}`]["donatiYerleri"][floorIdx] += donatiDict[rebar]["adet"]
                    }
                    else {
                        inputDonatiDict[floorIdx][`${diameter}-${length}`]["donatiYerleri"][floorIdx] = donatiDict[rebar]["adet"]
                    }
                }
                else {
                    inputDonatiDict[floorIdx][`${diameter}-${length}`] = {
                        "adet": donatiDict[rebar]["adet"],
                        "elemanlar": {
                            [rebar]: donatiDict[rebar]["adet"]
                        }
                    }
                    inputDonatiDict[floorIdx][`${diameter}-${length}`]["donatiYerleri"] = {}
                    inputDonatiDict[floorIdx][`${diameter}-${length}`]["donatiYerleri"][floorIdx] = donatiDict[rebar]["adet"]
                }
            }
            else {
                const inputDonati = inputDonatiDict[`${diameter}-${length}`]
                if (inputDonati) {
                    inputDonatiDict[`${diameter}-${length}`]["adet"] += donatiDict[rebar]["adet"]
                    inputDonatiDict[`${diameter}-${length}`]["elemanlar"][rebar] = donatiDict[rebar]["adet"]

                    const floorIdx = floorList.indexOf(donatiDict[rebar]["kat"])
                    if (inputDonatiDict[`${diameter}-${length}`]["donatiYerleri"][floorIdx]) {
                        inputDonatiDict[`${diameter}-${length}`]["donatiYerleri"][floorIdx] += donatiDict[rebar]["adet"]
                    }
                    else {
                        inputDonatiDict[`${diameter}-${length}`]["donatiYerleri"][floorIdx] = donatiDict[rebar]["adet"]
                    }
                }
                else {
                    inputDonatiDict[`${diameter}-${length}`] = {
                        "adet": donatiDict[rebar]["adet"],
                        "elemanlar": {
                            [rebar]: donatiDict[rebar]["adet"]
                        }
                    }
                    const floorIdx = floorList.indexOf(donatiDict[rebar]["kat"])
                    inputDonatiDict[`${diameter}-${length}`]["donatiYerleri"] = {}
                    inputDonatiDict[`${diameter}-${length}`]["donatiYerleri"][floorIdx] = donatiDict[rebar]["adet"]

                }
            }
        }
        /////////////////////////////////////////////////
        let optimizationInput = {}

        optimizationInput["katSayisi"] = floorList.length;
        optimizationInput["katlar"] = floorList;

        optimizationInput["donatiDict"] = donatiDict;
        optimizationInput["inputDonatiDict"] = inputDonatiDict;

        return optimizationInput;
    }

    const handleCpNameChange = (e) => {
        setCpName(e.target.value)
    };

    const handleFloorsChange = useCallback((checkedItems) => {
        const floorArray = floors.map((item) => item.value)
        checkedItems.sort((a, b) => floorArray.indexOf(a) - floorArray.indexOf(b))
        setSelectedFloors(checkedItems)
        if (checkedItems.length === 1) {
            setDisableSeparateFloors(true)
            setSepareteFloorsChecked(false)
        }
        else {
            setDisableSeparateFloors(false)
        }
    }, [floorRebarDict]);

    const handleDiametersChange = useCallback((checkedItems) => {
        setSelectedDiameters(checkedItems)
        if (checkedItems.length === 1) {
            setDisableUseSubstitutes(true)
            setUseSubstitutesChecked(false)
        }
        else {
            setDisableUseSubstitutes(false)
        }
    }, []);

    const handleMinBarLengthChange = (e) => {
        setMinBarLength(e.target.value)
    };

    const handleUseSubstitutesChange = (e) => {
        setUseSubstitutesChecked(!useSubstitutesChecked)
    };

    const handleSolveFloorByFloorChange = (e) => {
        setSepareteFloorsChecked(!separeteFloorsChecked)
    }

    const handleRebarCpSubmit = async (e, id) => {
        e.preventDefault();
        setIsCpLoading(true); ///////////////
        let rebars = []
        for (let i = 0; i < selectedFloors.length; i++) {
            const floor = selectedFloors[i]
            rebars = rebars.concat(floorRebarDict[floor])
        }
        const optimizationInput = createOptimizationInput(rebars)

        if (Object.keys(optimizationInput.inputDonatiDict).length === 0) {
            setErrorToast({ show: true, message: t("Optimize edilecek donatı bulunamadı.") })
            setIsCpLoading(false)
            return
        }

        try {
            const response = await createCp(projectType, id, cpName, optimizationInput, selectedDiameters, useSubstitutesChecked, separeteFloorsChecked, minBarLength, selectedFloors);

            if (response.ok) {
                const data = await response.json();
                const cpId = data.id
                // setIsCpLoading(false);
                window.location = `/${i18n.language}/cp-info/${cpId}`
            }
            else if (response.status === 400) {

                const data = await response.json();
                let errorDetail = data.detail
                const errorCode = data.hataKodu ? `(${t("Hata Kodu")} : ${data.hataKodu})` : ""
                setIsCpLoading(false);

                setErrorToast({
                    show: true,
                    message: `${errorDetail} ${errorCode}`,
                })
            }
            else {
                var props = {
                    error_code: "CT9VPC",
                    detail: `${response.statusText} (${response.status})`,
                }

                navigate(`/error`, { state: { props: props } })
            }
        }
        catch {
            var props = {
                error_code: "CT9VPC",
                detail: t("Sistem Hatası: Optimizasyon yapılamıyor. Sistem yöneticisi ile irtibata geçin."),
            }

            navigate(`/error`, { state: { props: props } })
        }

    };

    const handleMetalProfileCpSubmit = async (e, profileInfo, id) => {
        e.preventDefault();
        setIsCpLoading(true);
        let profileUpdateResponse
        try{
            profileUpdateResponse = await metalProfileInfoUpdate(id, profileInfo)
        }
        catch {
            setErrorToast({ show: true, message: t("Profil bilgileri güncellenirken hata oluştu. Sistem yöneticisine erişin. KE5E41") })
            setIsCpLoading(false)
            return
        }
        if (!profileUpdateResponse.ok) {
            try{
                const data = await profileUpdateResponse.json();
                let errorDetail = data.detail
                const errorCode = data.hataKodu ? `(${t("Hata Kodu")} : ${data.hataKodu})` : ""
                setErrorToast({
                    show: true,
                    message: `${errorDetail} ${errorCode}`,
                })
            }
            catch{
                setErrorToast({ show: true, message: t("Profil bilgileri güncellenirken hata oluştu. Sistem yöneticisine erişin. P6QQR6") })
                setIsCpLoading(false)
                return
            }
            
        }

        let cpResponse
        try {
            cpResponse = await createCp(projectType, id, cpName, null, null, null, null, null, null);
        }
        catch {
            setErrorToast({ show: true, message: t("Optimizasyon yapılırken hata oluştu. Sistem yöneticisine erişin. KE5E41") })
            setIsCpLoading(false)
            return
        }
        if(!cpResponse.ok){
            try{
                const data = await cpResponse.json();
                let errorDetail = data.detail
                const errorCode = data.hataKodu ? `(${t("Hata Kodu")} : ${data.hataKodu})` : ""
                setErrorToast({
                    show: true,
                    message: `${errorDetail} ${errorCode}`,
                })
            }
            catch{
                setErrorToast({ show: true, message: t("Optimizasyon yapılırken hata oluştu. Sistem yöneticisine erişin. P6QQR6") })
                setIsCpLoading(false)
                return
            }
        }
        const data = await cpResponse.json();
        const cpId = data.id
        window.location = `/${i18n.language}/cp-info/${cpId}`
        setIsCpLoading(false)
    }

    return {
        projectName,
        projectType,

        profileInfo,
        setProfileInfo,

        diameters,
        floors,
        isLoading,
        isCpLoading,
        errorToast,
        disableSeparateFloors,
        disableUseSubstitutes,

        useSubstitutesChecked,
        separeteFloorsChecked,

        setErrorToast,
        handleCpNameChange,
        handleFloorsChange,
        handleDiametersChange,
        handleMinBarLengthChange,
        handleUseSubstitutesChange,
        handleSolveFloorByFloorChange,
        handleRebarCpSubmit,

        handleMetalProfileCpSubmit
    };
};

export default useCpCreation;
