import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useCpInfo = (id, result, userType) => {
    const { i18n, t } = useTranslation();

    const [isComponentLoading, setIsComponentLoading] = useState(true)

    const [allPieces, setAllPieces] = useState([])
    const [allProfilesInfo, setAllProfilesInfo] = useState([])
    const [cuttingResults, setCuttingResults] = useState({})
    const [showedProfiles, setShowedProfiles] = useState([])
    const [profilesForCheckboxList, setProfilesForCheckboxList] = useState([])
    const [checkedProfiles, setCheckedProfiles] = useState([])
    const [patternsByProfile, setPatternsByProfile] = useState({})
    const [allPatternsByProfile, setAllPatternsByProfile] = useState({})

    const [currentPatterns, setCurrentPatterns] = useState({})
    const [currentWastes, setCurrentWastes] = useState([])
    const [currentResultSummary, setCurrentResultSummary] = useState({})

    const [allStatistics, setAllStatistics] = useState({})

    const [cuttingPlanInfo, setCuttingPlanInfo] = useState({})

    const [showPieceInfo, setShowPieceInfo] = useState(true)

    function getCurrentPatterns(cuttingResults, showedProfiles, checkedProfiles) {
        let patternsByProfile = {}
        for (const group_id of checkedProfiles) {
            const patterns = cuttingResults[group_id].patterns
            const profileInfo = showedProfiles.find(profile => { return profile.group_id.toString() === group_id.toString() })
            for (let i = 0; i < patterns.length; i++) {
                patterns[i]['logLength'] = profileInfo.stock_length
                patterns[i]['bladeThickness'] = profileInfo.blade_thickness
                const piece_codes = Object.keys(patterns[i].pieces)
                const piece_info = allPieces.filter(piece => { return piece_codes.includes(piece.piece_code) })
                patterns[i]['piecesInfo'] = piece_info
            }
            patternsByProfile[group_id] = {
                profileName: `${profileInfo.profile} (${profileInfo.grade})`,
                logLength: profileInfo.stock_length,
                bladeThickness: profileInfo.blade_thickness,
                ...cuttingResults[group_id]
            }
        }
        return patternsByProfile
    }

    function getCurrentWastes(patternsByProfile, profiles) {

        let wastesByLengthAndProfile = {}

        for (const profileId in patternsByProfile) {

            const patterns = patternsByProfile[profileId].patterns
            for (const pattern of patterns) {
                const wasteLength = pattern.waste
                if (wasteLength === 0) {
                    continue
                }
                const nLogs = pattern.nLogs
                if (!(profileId in wastesByLengthAndProfile)) {
                    wastesByLengthAndProfile[profileId] = {
                        [wasteLength]: {
                            "nLogs": nLogs,
                            "patterns": [pattern.patternNo]
                        }
                    }
                }
                else if (!(wasteLength in wastesByLengthAndProfile[profileId])) {
                    wastesByLengthAndProfile[profileId][wasteLength] = {
                        "nLogs": nLogs,
                        "patterns": [pattern.patternNo]
                    }
                }
                else {
                    wastesByLengthAndProfile[profileId][wasteLength].nLogs += nLogs
                    wastesByLengthAndProfile[profileId][wasteLength].patterns.push(pattern.patternNo)
                }
            }
        }

        const wastes = []
        for (const profileId in wastesByLengthAndProfile) {

            const profileInfo = profiles.find(profile => { return profile.group_id.toString() === profileId.toString() })

            for (const wasteLength in wastesByLengthAndProfile[profileId]) {
                wastes.push({
                    length: wasteLength,
                    quantity: wastesByLengthAndProfile[profileId][wasteLength].nLogs,
                    profile: profileInfo.profile,
                    grade: profileInfo.grade,
                    patterns: wastesByLengthAndProfile[profileId][wasteLength].patterns.toString(),
                })
            }
        }

        wastes.sort((a, b) => {
            if (a.profile === b.profile) {
                return Number(a.length) - Number(b.length)
            }
            else {
                return a.profile > b.profile
            }
        })
        return wastes
    }

    function calculateStatistics(currentResults) {
        let totalLogs = 0
        let totalWeight = 0
        let wastedWeight = 0
        for (const group_id in currentResults) {
            const results = currentResults[group_id]
            totalLogs += results.totalLogsUsed
            totalWeight += results.totalLogWeight
            wastedWeight += results.totalWastedWeight
        }
        return {
            selectedProfiles: Object.values(currentResults).map(item => item.profileName),
            totalLogs: totalLogs,
            totalWeight: totalWeight.toFixed(2),
            wastedWeight: wastedWeight.toFixed(2),
            yieldPercentage: ((totalWeight - wastedWeight) * 100 / totalWeight).toFixed(2),
            wastePercentage: ((wastedWeight) * 100 / totalWeight).toFixed(2)
        }
    }

    useEffect(() => {
        const _currentPatterns = getCurrentPatterns(cuttingResults, showedProfiles, checkedProfiles)
        setCurrentPatterns(_currentPatterns)
        const _currentWastes = getCurrentWastes(_currentPatterns, showedProfiles)
        setCurrentWastes(_currentWastes)
        setCurrentResultSummary(calculateStatistics(_currentPatterns))
    }, [checkedProfiles])

    function calculateAllStatistics(results) {
        return Object.values(results).map((result) => ({
            "profileName": result.profileName,
            "executionTime": result.executionTime,
            "totalLogsUsed": result.totalLogsUsed,
            "totalLogLength": result.totalLogLength,
            "totalUsedLength": result.totalUsedLength,
            "totalWaste": result.totalWaste.toFixed(2),
            "usedPercentage": result.usedPercentage,
            "wastedPercentage": result.wastedPercentage,
            "totalLogWeight": result.totalLogWeight,
            "totalUsedWeight": result.totalUsedWeight,
            "totalWastedWeight": result.totalWastedWeight,
        }))
    }


    useEffect(() => {
        let status = result.status
        const activated = result.activated

        if(status === 'pending'){
            status = t('Sıraya alındı')
        }
        else if(status === 'processing'){
            status = t('Hesaplanıyor...')
        }
        else{
            status = t('Tamamlandı')
        }

        let _cuttingPlanInfo = {
            cpId: id,
            cpName: result.cpName,
            status: status,
            projectId: result.projectId,
            projectName: result.projectName,
            projectUser: `${result.projectUser.email} (${result.projectUser.id})`,
            creationTime: result.creationTime,
            projectTheoreticalWeight: result.projectTheoreticalWeight,
            projectTheoreticalLength: result.projectTheoreticalLength,
        }

        if(result.status === 'completed'){
            _cuttingPlanInfo = {
                ..._cuttingPlanInfo,
                allLogWeight: result.summary.allLogWeight,
                allWastedWeight: result.summary.allWastedWeight,
                percentYield: result.summary.percentYield
            }
            setAllProfilesInfo(result.optimization_groups)
        }

        setCuttingPlanInfo(_cuttingPlanInfo)

        if(!result.activated){
            setIsComponentLoading(false)
            return
        }

        setAllPieces(result.pieces)

        function getPartsOfLongPiece(piece_length, stock_length) {
            const parts = []
            while (piece_length > stock_length) {
                parts.push(Number(stock_length))
                piece_length -= stock_length
            }
            parts.push(Number(piece_length).toFixed(2))
            return parts
        }

        for (let i = 0; i < result.pieces.length; i++) {
            const piece = result.pieces[i]
            const group_id = piece.group_id
            const group = result.optimization_groups.find(profile => { return profile.group_id.toString() === group_id.toString() })
            if (Number(piece['length']) > Number(group.stock_length)) {
                result.pieces[i]['is_long'] = true
                result.pieces[i]['parts'] = getPartsOfLongPiece(Number(piece['length']), Number(group.stock_length))
            }
        }

        setCuttingResults(result.results)

        const _showedProfiles = result.optimization_groups.filter((item) => {
            let showValue = item.include_in_optimization
            if (showValue === true || showValue === 'true' || showValue === 'True' || showValue === 1 || showValue === '1') {
                return true
            }
            else {
                return false
            }
        })

        setShowedProfiles(_showedProfiles)
        const _profilesForCheckboxList = _showedProfiles.map((item) => (
            {
                label: `${item.profile} (${item.grade})`,
                value: item.group_id,
            }
        ))
        setProfilesForCheckboxList(_profilesForCheckboxList)

        setCheckedProfiles([_profilesForCheckboxList[0].value])

        const _patternsByProfile = getCurrentPatterns(result.results, _showedProfiles, [_profilesForCheckboxList[0].value])
        setPatternsByProfile(_patternsByProfile)

        const _allPatternsByProfile = getCurrentPatterns(result.results, _showedProfiles, _profilesForCheckboxList.map(i => i.value))
        setAllPatternsByProfile(_allPatternsByProfile)

        setCurrentPatterns(_patternsByProfile)

        setCurrentWastes(getCurrentWastes(_patternsByProfile, _showedProfiles))

        setCurrentResultSummary(calculateStatistics(_patternsByProfile))

        setAllStatistics(calculateAllStatistics(_allPatternsByProfile))

        setIsComponentLoading(false)
    }, [])



    return {

        isComponentLoading,

        allPieces,
        allProfilesInfo,

        profilesForCheckboxList,

        setCheckedProfiles,

        currentPatterns,
        currentWastes,
        currentResultSummary,
        allStatistics,
        cuttingPlanInfo,

        showPieceInfo,
        setShowPieceInfo,
    }
}

export default useCpInfo;