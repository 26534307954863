import React from 'react';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';

import { useTranslation } from 'react-i18next';

const SolutionOptions = ({ cuttingPlanInfo, allProfilesInfo }) => {
    const { i18n, t } = useTranslation();

    return (
        <Container fluid className='border border-top-0 p-3' style={{ backgroundColor: 'white' }}>

            <Container className="border shadow-sm p-4">
                <Table>
                    <tbody>
                        <tr>
                            <th scope="row" style={{ width: '50%' }}>{t("Kesim Planı")}</th>
                            <td style={{ width: '50%' }}>{cuttingPlanInfo.cpName}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Proje")}</th>
                            <td><a href={`/project-info/${cuttingPlanInfo.projectId}`} target='_blank'>{`${cuttingPlanInfo.projectName} (${cuttingPlanInfo.projectId})`}</a></td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Kullanıcı")}</th>
                            <td>{cuttingPlanInfo.projectUser}</td>
                        </tr>
                        <tr>
                            <th scope="row">{t("Oluşturma Zamanı")}</th>
                            <td>{new Date(cuttingPlanInfo.creationTime).toLocaleDateString(i18n.language)} {new Date(cuttingPlanInfo.creationTime).toLocaleTimeString(i18n.language)}</td>
                        </tr>
                    </tbody>

                </Table>
            </Container>

            <Container className="border shadow-sm my-4 p-4">

                <Table>

                    <thead>
                        <tr>
                            <th style={{width:'20%'}}>{t("Profil")}</th>
                            <th style={{width:'15%'}}>{t("Malzeme")}</th>
                            <th style={{width:'15%'}}>{t("Optimizasyona Dahil")}</th>
                            <th style={{width:'20%'}}>{t("Kesim Yapılan Boy")} (mm)</th>
                            <th style={{width:'15%'}}>{t("Testere Kalınlığı")} (mm)</th>
                            <th style={{width:'15%'}}>{t("Birim Ağırlık")} (kg/m)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allProfilesInfo.map((profile, index) => {
                            let include = t('Hayır')
                            const trueValues = [true, 'True', 1, '1']
                            if(trueValues.includes(profile.include_in_optimization)){
                                include = t('Evet')
                            }
                            return <tr key={index}>
                                <th>{profile.profile}</th>
                                <td>{profile.grade}</td>
                                <td>{include}</td>
                                <td>{profile.stock_length}</td>
                                <td>{profile.blade_thickness}</td>
                                <td>{profile.kg_weight_per_meter.toFixed(2)}</td>
                            </tr>

                        })}
                    </tbody>

                </Table>

            </Container>


        </Container>
    )
}

export default SolutionOptions;
