import React, { useState } from 'react';
import { useParams } from "react-router-dom";

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Toast from 'react-bootstrap/Toast';

import AppNavbar from '../../components/AppNavbar/AppNavbar';
import Footer from '../../components/Footer/Footer';

import mainStyles from '../Pages.module.css';
import styles from './CpCreation.module.css';

import useCpCreation from '../../hooks/useCpCreation/useCpCreation';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import LoadingContainer from '../../components/LoadingContainer/LoadingContainer';

import RebarCpCreation from './RebarCpCreation';

import { useTranslation } from 'react-i18next';
import MetalProfileCpCreation from './MetalProfileCpCreation';

const CpCreation = () => {

    const { id } = useParams();

    const {
        projectName,
        projectType,

        profileInfo,
        setProfileInfo,

        diameters,
        floors,
        isLoading,
        isCpLoading,
        errorToast,
        disableSeparateFloors,
        disableUseSubstitutes,

        useSubstitutesChecked,
        separeteFloorsChecked,

        setErrorToast,
        handleCpNameChange,
        handleFloorsChange,
        handleDiametersChange,
        handleMinBarLengthChange,
        handleUseSubstitutesChange,
        handleSolveFloorByFloorChange,
        handleRebarCpSubmit,

        handleMetalProfileCpSubmit
    } = useCpCreation(id);

    const { i18n, t } = useTranslation();

    document.title = t("Kesim Planı Oluştur")

    const [dialogOpen, setDialogOpen] = useState(false)
    const [dialogContent, setDialogContent] = useState(<div></div>)

    let cpCreationComponent = null;
    if (projectType === 'rebar') {
        cpCreationComponent = <RebarCpCreation
            floors={floors}
            diameters={diameters}
            id={id}
            handleSubmit={handleRebarCpSubmit}
            handleCpNameChange={handleCpNameChange}
            handleFloorsChange={handleFloorsChange}
            handleDiametersChange={handleDiametersChange}
            handleUseSubstitutesChange={handleUseSubstitutesChange}
            handleSolveFloorByFloorChange={handleSolveFloorByFloorChange}
            handleMinBarLengthChange={handleMinBarLengthChange}
            useSubstitutesChecked={useSubstitutesChecked}
            separeteFloorsChecked={separeteFloorsChecked}
            disableUseSubstitutes={disableUseSubstitutes}
            disableSeparateFloors={disableSeparateFloors}
        />
    }
    else {
        cpCreationComponent = <MetalProfileCpCreation
            id={id}
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
            handleCpNameChange={handleCpNameChange}
            handleSubmit={handleMetalProfileCpSubmit}
        />
    }

    return (
        <div className={mainStyles.flexWrapper}>
            {dialogOpen &&
                <Dialog
                    maxWidth="xs"
                    fullWidth={true}
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}>
                    <DialogContent>
                        {dialogContent}
                    </DialogContent>
                </Dialog>}
            <AppNavbar />
            <div className={mainStyles.content}>

                {isCpLoading && (
                    <Backdrop
                        sx={{
                            color: '#fff',
                            bgcolor: 'rgba(0,0,0,0.7)',
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                        open={isCpLoading}
                    >
                        <p className="mt-2 text-center">{t("Optimizasyon yapılıyor. Bu biraz zaman alabilir, lütfen bekleyiniz. Sayfayı kapatmayınız.")}</p>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                )}

                {isLoading ?
                    LoadingContainer :
                    <>
                        <Container >
                            <Card className={styles.pageHeaderCard}>
                                <Card.Body className={styles.headerCardBody}>
                                    <a href={`/${i18n.language}/project-info/${id}`}><h5 className={styles.smallText}>{projectName}</h5></a>
                                    <h4 className={styles.mySeparator}>&gt;</h4>
                                    <h1 className={styles.pageHeader}>{t("Yeni Kesim Planı")}</h1>
                                </Card.Body>
                            </Card>
                        </Container>

                        {cpCreationComponent}
                    </>
                }
            </div>
            <Toast
                onClose={() => setErrorToast({ show: false, message: '' })}
                show={errorToast.show}
                bg="danger"
                delay={6000}
                autohide
                style={{
                    position: 'fixed',
                    bottom: 20,
                    left: 20,
                    zIndex: 9999,
                }}
            >
                <Toast.Header>
                    <strong className="me-auto">{t("Hata")}</strong>
                </Toast.Header>
                <Toast.Body style={{ color: 'white' }}>{errorToast.message}</Toast.Body>
            </Toast>
            <Footer />
        </div >
    )
}

export default CpCreation;