import React from 'react';

import useCpInfo from '../../../hooks/useCpInfo/useRebarCpInfo';
import InactiveCpInfo from './RebarInactiveCpInfo';

import CpInfoHeader from './RebarCpInfoHeader';
import ActiveCpInfo from './RebarActiveCpInfo';
import BackgroundCpInfo from './RebarBackgroundCpInfo';

const RebarCpInfo = ({
    id,
    result,
    projectUnitPrice,
    unitDiscount,
    unitPriceType,
    purchasable,
    userType,
    ownCp,
    owner,
    backgroundTask
}) => {

    const {
        isComponentLoading,

        showLowerFloorCheck,

        diameters,
        floors,
        checkedDiameter,
        checkedFloors,
        currentPatterns,
        showedPatterns,

        currentSubstitutes,
        currentWastes,

        resultOptions,
        currentResultSummary,

        handleDiametersChange,
        handleFloorsChange,
        handleShowLowerFloorPatterns,
    } = useCpInfo(id, result, userType);

    let CpInfoContainer;

    const CpHeader = (
        <div className='mx-2'>
            <CpInfoHeader
                id={id}

                userType={userType}
                activated={result.activated}
                backgroundTask={backgroundTask}
                ownCp={ownCp}
                owner={owner}
                result={result}

                checkedDiameter={checkedDiameter}
                checkedFloors={checkedFloors}
                currentPatterns={currentPatterns}
                showedPatterns={showedPatterns}
                currentSubstitutes={currentSubstitutes}
                currentWastes={currentWastes}
                currentResultSummary={currentResultSummary}
            />
        </div>
    )

    if (isComponentLoading) {
        CpInfoContainer = <div></div>
    }
    else if (backgroundTask) {
        CpInfoContainer = <>
            {CpHeader}
            <BackgroundCpInfo
                userType={userType}
                ownCp={ownCp}
                owner={owner}
                result={result}
            />
        </>
    }
    else {
        if ([4, 5].includes(userType) || result.activated) {
            CpInfoContainer = <>
                {CpHeader}
                <ActiveCpInfo
                    floorAvailable={true}
                    showLowerFloorCheck={showLowerFloorCheck}

                    result={result}

                    diameters={diameters}
                    floors={floors}
                    checkedDiameter={checkedDiameter}
                    checkedFloors={checkedFloors}
                    currentPatterns={currentPatterns}
                    showedPatterns={showedPatterns}

                    currentSubstitutes={currentSubstitutes}
                    currentWastes={currentWastes}

                    resultOptions={resultOptions}
                    currentResultSummary={currentResultSummary}

                    handleDiametersChange={handleDiametersChange}
                    handleFloorsChange={handleFloorsChange}
                    handleShowLowerFloorPatterns={handleShowLowerFloorPatterns}
                />
            </>
        }
        else {
            CpInfoContainer = <>
                {CpHeader}
                <InactiveCpInfo
                    result={result}
                    projectUnitPrice={projectUnitPrice}
                    unitDiscount={unitDiscount}
                    purchasable={purchasable}
                    ownCp={ownCp}
                />
            </>
        }
    }

    return  CpInfoContainer;
};

export default RebarCpInfo;
