import React, { useMemo } from 'react';
import { MaterialReactTable } from 'material-react-table';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import usePurchases from '../../../hooks/admin/usePurchases';
import LoadingContainer from '../../../components/LoadingContainer/LoadingContainer';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner'

import { iso31661_country_codes } from '../../../other/variables';
import { iso31662_state_codes } from '../../../other/variables';

const Purchases = () => {

    const {
        isLoading,
        selectedMethod,
        selectedPurchased,

        handleMethodChange,
        handlePurchasedChange,

        tableData,
        tableKeys,

        modalInfo,
        modalOnShow,
        modalOnHide,

        loadedInvoiceFile,
        handleInvoiceUpload,

        handleBankReceiptUpload,

        showCancelModalFooter,
        setShowCancelModalFooter,

        handleApproveProjectPayment,
        approveProjectPaymentLoading,

        cancelMessage,
        setCancelMessage,

        handleCancelProjectPayment,
        cancelProjectPaymentLoading,

        handleDownloadInvoice,

        mailLanguage,
        setMailLanguage,
    } = usePurchases()

    const columns = useMemo(
        () => tableKeys, [tableKeys],
    );

    if (isLoading) {
        return LoadingContainer
    }

    let modal;
    let transfer = modalInfo.type === "project-transfer"

    let delegate = "delegation_id" in modalInfo.params

    let uploadInvoice = <Row>
        <Col>

            <Form.Group>
                <Form.Label>Faturayı Yükle (zorunlu)</Form.Label>
                <Form.Control type="file" accept=".pdf" id="invoice"
                    onChange={(e) => { handleInvoiceUpload(e) }}
                />
            </Form.Group>

        </Col>
        <Col>
            <Form.Group>
                <Form.Label>Dekontu Yükle (zorunlu değil)</Form.Label>
                <Form.Control type="file" accept=".pdf" id="bank_receipt"
                    onChange={(e) => { handleBankReceiptUpload(e) }}
                />
            </Form.Group>
        </Col>
    </Row>;

    if (modalInfo.type.split('-')[0] === "project") {
        let date_created = (new Date(modalInfo.params.payment_detail.date_created)).toDateString()
        let time_created = (new Date(modalInfo.params.payment_detail.date_created)).toTimeString()
        let date_completed = ""
        let time_completed = ""
        if (transfer) {
            if (modalInfo.type === "project-transfer") {
                date_completed = (new Date(modalInfo.params.payment_detail.date_completed)).toDateString()
                time_completed = (new Date(modalInfo.params.payment_detail.date_completed)).toTimeString()
            }
        }
        modal = <Form>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Sipariş/Ödeme Detayı
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <h5>Sipariş/Ödeme Bilgisi</h5>
                        <table className='table table-sm'>
                            <tbody>
                                <tr>
                                    <th>
                                        Sipariş Numarası
                                    </th>
                                    <td>
                                        {modalInfo.params.order_id}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Sipariş Tarihi
                                    </th>
                                    <td>
                                        {`${date_created} ${time_created}`}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Durumu
                                    </th>
                                    <td>
                                        {modalInfo.params.status}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Sipariş Veren Kullanıcı
                                    </th>
                                    <td>
                                        {`${modalInfo.params.user_email} (${modalInfo.params.user})`}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Sipariş Edilen Proje
                                    </th>
                                    <td>
                                        <a href={`project-info/${modalInfo.params.payment_detail.project}`} target='_blank'>{`${modalInfo.params.payment_detail.project_name} (${modalInfo.params.payment_detail.project})`}</a>
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Proje Ağırlığı
                                    </th>
                                    <td>
                                        {(modalInfo.params.payment_detail.project_weight / 1000).toFixed(3)} ton
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Birim Fiyat
                                    </th>
                                    <th>
                                        {`${modalInfo.params.payment_detail.unit_price} ₺/ton`}
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        {modalInfo.params.payment_detail.vat !== 0 && "KDV "}
                                        {modalInfo.params.payment_detail.vat !== 0 && modalInfo.params.payment_detail.discount_rate !== 0 && "& "}
                                        {modalInfo.params.payment_detail.discount_rate !== 0 && "İndirim "}
                                        {(modalInfo.params.payment_detail.vat !== 0 || modalInfo.params.payment_detail.discount_rate) !== 0 && "Dahil "}
                                        Toplam Fiyat
                                    </th>
                                    <th>
                                        {`${modalInfo.params.payment_detail.total_price} ₺`}
                                    </th>
                                </tr>
                                {delegate &&
                                    <>
                                        <tr>
                                            <th>
                                                Temsilci Kesintisi
                                            </th>
                                            <th>
                                                {`${Math.floor(modalInfo.params.payment_detail.delegation_cut_unit_price * modalInfo.params.payment_detail.project_weight * 1e-3)} ₺ (${modalInfo.params.payment_detail.delegation_cut_unit_price} ₺/ton)`}
                                            </th>
                                        </tr>
                                        <tr>
                                            <th>
                                                Temsilci Kesintisi Sonrası Kalan
                                            </th>
                                            <th>
                                                {`${modalInfo.params.payment_detail.total_price - Math.floor(modalInfo.params.payment_detail.delegation_cut_unit_price * modalInfo.params.payment_detail.project_weight * 1e-3)} ₺`}
                                            </th>
                                        </tr>
                                    </>

                                }
                                <tr>
                                    <th>
                                        KDV
                                    </th>
                                    <th>
                                        {`%${100 * modalInfo.params.payment_detail.vat}`}
                                    </th>
                                </tr>
                                <tr>
                                    <th>
                                        İndirim Oranı
                                    </th>
                                    <th>
                                        %{(100 * modalInfo.params.payment_detail.discount_rate).toFixed(2)}
                                    </th>
                                </tr>
                                {modalInfo.params.payment_detail.vat !== 0 &&
                                    <tr>
                                        <th>
                                            KDV Hariç Toplam Fiyat
                                        </th>
                                        <th>
                                            {`${(modalInfo.params.payment_detail.total_price / (1 + modalInfo.params.payment_detail.vat)).toFixed(2)} ₺`}
                                        </th>
                                    </tr>
                                }

                                {transfer ?

                                    <>{modalInfo.params.payment_detail.completed ?
                                        <tr>
                                            <th>
                                                Tamamlanma Tarihi
                                            </th>
                                            <td>
                                                {`${date_completed} ${time_completed}`}
                                            </td>
                                        </tr> : <></>}
                                        {modalInfo.params.status === "failed" ?
                                            <tr>
                                                <th>İptal Mesajı</th>
                                                <td>{modalInfo.params.payment_detail.cancel_message}</td>
                                            </tr> : <></>}
                                    </>
                                    :
                                    <>
                                        <tr>
                                            <th>Kart</th>
                                            <td>**** **** **** {modalInfo.params.payment_detail.card_last_four_no} ({modalInfo.params.payment_detail.card_type})</td>
                                        </tr>
                                        <tr>
                                            <th>Ödeme Mesajı</th>
                                            <td>{modalInfo.params.payment_detail.message}</td>
                                        </tr>
                                    </>
                                }
                            </tbody>
                        </table>
                    </Col>
                    <Col>
                        <h5>Fatura Bilgisi</h5>
                        <table className='table table-sm'>
                            <tbody>
                                <tr>
                                    <th>
                                        Fatura Tipi
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.type}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Ad
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.name ? modalInfo.params.invoice_details.name : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Soyad
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.surname ? modalInfo.params.invoice_details.surname : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        TCKN
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.tr_identity_number ? modalInfo.params.invoice_details.tr_identity_number : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Telefon
                                    </th>
                                    <td>
                                        +{modalInfo.params.invoice_details.telephone_country_code}{modalInfo.params.invoice_details.telephone}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Şirket Adı
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.company_name ? modalInfo.params.invoice_details.company_name : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Vergi No
                                    </th>
                                    <td>
                                        {modalInfo.params.invoice_details.taxno ? modalInfo.params.invoice_details.taxno : ""}
                                    </td>
                                </tr>
                                <tr>
                                    <th colSpan={2}>
                                        Adres
                                    </th>
                                </tr>
                                {modalInfo.params.invoice_details.street &&
                                    <tr><td colSpan={2}>
                                        {modalInfo.params.invoice_details.street}
                                    </td></tr>
                                }
                                {modalInfo.params.invoice_details.address_line1 &&
                                    <tr><td colSpan={2}>
                                        {modalInfo.params.invoice_details.address_line1}
                                    </td></tr>
                                }
                                {modalInfo.params.invoice_details.address_line2 &&
                                    <tr><td colSpan={2}>
                                        {modalInfo.params.invoice_details.address_line2}
                                    </td></tr>
                                }
                                {modalInfo.params.invoice_details.zipcode &&
                                    <tr><td colSpan={2}>
                                        {modalInfo.params.invoice_details.zipcode}
                                    </td></tr>
                                }
                                {modalInfo.params.invoice_details.country &&
                                    <tr><td colSpan={2}>
                                        {`${iso31661_country_codes[modalInfo.params.invoice_details.country]} / ${iso31662_state_codes[modalInfo.params.invoice_details.province]} / ${modalInfo.params.invoice_details.county}`}
                                    </td></tr>
                                }

                                {modalInfo.params.invoice_details.invoice_url !== null ?
                                    <tr>
                                        <th colSpan={2}>
                                            <a href="#" onClick={() => handleDownloadInvoice(modalInfo.params.order_id)}>Faturayı İndir</a>
                                        </th>
                                    </tr>

                                    : <></>}
                            </tbody>
                        </table>
                        {delegate &&

                            <>
                                <h5>Temsilci Bilgisi</h5>

                                <table className='table table-sm'>
                                    <tbody>
                                        <tr>
                                            <th>Temsilcilik Numarası</th>
                                            <td>{modalInfo.params.delegation_id}</td>
                                        </tr>
                                        <tr>
                                            <th>Temsilci Adı</th>
                                            <td>{modalInfo.params.delegate_name}</td>
                                        </tr>
                                        <tr>
                                            <th>Temsilci E-posta</th>
                                            <td>{modalInfo.params.delegate_email} ({modalInfo.params.delegate_user_id})</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>

                        }
                    </Col>
                </Row>

                <Form.Group className='mb-2'>
                    <Form.Label>E-posta Dili</Form.Label>
                    <Form.Select
                        value={mailLanguage}
                        onChange={(e) => { setMailLanguage(e.target.value) }}
                    >
                        <option value="tr">Türkçe</option>
                        <option value="en">English</option>
                    </Form.Select>
                </Form.Group>

                {transfer ?
                    !(modalInfo.params.payment_detail.completed) ?
                        showCancelModalFooter ?
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>
                                    <h4 style={{ color: "red", textAlign: "center" }}>Ödeme yapılmışsa ödemeyi reddetmeden önce kullanıcının yatırdığı parayı iade etmeyi unutmayınız!</h4>
                                    <p>Reddetme sebebinizi kısaca yazınız: (Bu mesaj kullanıcıya gidecek olan e-posta'da ve sipariş sayfasında yazacak. Mesajsız reddetmek için boş bırakın.)</p>
                                </Form.Label>
                                <Form.Control
                                    as="textarea"
                                    value={cancelMessage}
                                    onChange={(e) => { setCancelMessage(e.target.value) }}
                                    rows={3}
                                    maxLength={400}
                                    disabled={cancelProjectPaymentLoading}
                                />
                            </Form.Group>
                            :
                            uploadInvoice
                        : <></>
                    :
                    modalInfo.params.status === "success" && modalInfo.params.invoice_details.invoice_url === null ?
                        uploadInvoice
                        :
                        <></>
                }
                {modalInfo.note !== "" ?
                    <h4 className={`mt-3 text-center text-${modalInfo.note_variant}`}>{modalInfo.note}</h4> : <></>}
            </Modal.Body>


            {transfer ?
                showCancelModalFooter ?
                    <Modal.Footer className='d-flex justify-content-around'>
                        <>
                            <p>Siparişi reddetmek istediğinizden emin misiniz? </p>
                            <Button
                                size='lg'
                                variant='info'
                                onClick={() => { setShowCancelModalFooter(false); setCancelMessage("") }}
                                disabled={cancelProjectPaymentLoading}
                            >
                                HAYIR
                            </Button>
                            <Button
                                variant='danger'
                                onClick={() => handleCancelProjectPayment(modalInfo.params.order_id)}
                                disabled={cancelProjectPaymentLoading}
                            >
                                {cancelProjectPaymentLoading ? <Spinner size='sm' /> : "EVET"}
                            </Button>
                        </>
                    </Modal.Footer> :
                    <Modal.Footer className='d-flex justify-content-around'>
                        {!modalInfo.params.payment_detail.completed ?
                            <>
                                <Button
                                    size='lg'
                                    variant='danger'
                                    disabled={approveProjectPaymentLoading}
                                    onClick={() => setShowCancelModalFooter(true)}
                                >REDDET</Button>
                                {approveProjectPaymentLoading ?
                                    <Button
                                        size='lg'
                                        variant='success'
                                        disabled
                                    >
                                        <Spinner size='sm' />
                                    </Button> :
                                    <Button
                                        size='lg'
                                        variant='success'
                                        disabled={loadedInvoiceFile === null}
                                        onClick={() => handleApproveProjectPayment(modalInfo.params.order_id)}
                                    >ONAYLA</Button>}
                            </> : <></>}
                    </Modal.Footer>
                :
                modalInfo.params.status === "success" && modalInfo.params.invoice_details.invoice_url === null ?
                    <Modal.Footer className='d-flex justify-content-around'>

                        {approveProjectPaymentLoading ?
                            <Button
                                size='lg'
                                variant='success'
                                disabled
                            >
                                <Spinner size='sm' />
                            </Button> :
                            <Button
                                size='lg'
                                variant='success'
                                disabled={loadedInvoiceFile === null}
                                onClick={() => handleApproveProjectPayment(modalInfo.params.order_id)}
                            >YÜKLE</Button>}

                    </Modal.Footer>
                    :
                    <></>
            }
        </Form>
    }
    else {
        modal = <Form>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    hey
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{JSON.stringify(modalInfo.params)}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button>hey</Button>
            </Modal.Footer>
        </Form>
    }

    return <Container>
        <Row className='mb-4'>
            <Col>
                <Form.Select
                    onChange={(e) => handlePurchasedChange(e.target.value)}
                    value={selectedPurchased}
                >
                    <option value="1">Satın alınan hizmeti seçin</option>
                    <option value="project">Proje</option>
                    <option value="credits" disabled>Kredi</option>
                </Form.Select>
            </Col>
            <Col>
                <Form.Select
                    onChange={(e) => handleMethodChange(e.target.value)}
                    value={selectedMethod}
                >
                    <option value="1">Ödeme yöntemini seçin</option>
                    <option value="transfer">Havale / EFT</option>
                    <option value="credit_card">Kredi / Banka Kartı</option>
                </Form.Select>
            </Col>
        </Row>


        <MaterialReactTable
            columns={columns}
            data={tableData}
            muiTableBodyRowProps={({ row }) => ({
                onClick: (event) => {
                    modalOnShow(`${selectedPurchased}-${selectedMethod}`, row.original)
                },
                sx: {
                    cursor: 'pointer', //you might want to change the cursor too when adding an onClick
                },
            })} />

        <Modal
            show={modalInfo.show}
            onHide={() => modalOnHide()}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            animation={false}
        >
            {modal}
        </Modal>

    </Container>




};

export default Purchases;
