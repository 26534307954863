import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import styles from './Projects.module.css';
import { Link } from 'react-router-dom';

const MetalProfileProjectsTab = ({ projects }) => {

    const { t, i18n } = useTranslation();

    return (
        <Container className='border border-top-0 py-3'>

            <div className={styles.cardTitle}>{t("Geçmiş Çelik Profil Projeleri")}</div>
            <Row className="align-items-center fw-bold m-2 mt-4" >
                <Col className={styles.boldText}>{t("İsim")}</Col>
                <Col className="d-none d-sm-block">{t("Dosya Türü")}</Col>
                <Col >{t("Teorik Ağırlık (ton)")}</Col>
                <Col className="d-none d-sm-block">{t("Aktif")}</Col>
                <Col >{t("Tarih")}</Col>
            </Row>
            <hr className={styles.separator} />
            {projects.map(project => {
                const id = project.id

                let software
                if (project.uploadedFileType === 'sta') {
                    software = "STASteel"
                } else if (project.uploadedFileType === 'ide') {
                    software = "ideCad"
                } else if (project.uploadedFileType === 'xsteel') {
                    software = "XSteel"
                }else {
                    software = t("Diğer")
                }

                let rawDate = new Date(project.creationTime);

                const tonWeight = (project.theoreticalWeight / 1000).toFixed(1)

                const date = rawDate.toLocaleDateString(i18n.language);

                const pname = project.name === "Ornek-Proje" ? t("Ornek-Proje") : project.name

                return (
                    <Link key={project.id} to={`/${i18n.language}/project-info/${id}`} className={styles.projectLink}>
                        <Row className={`align-items-center ${styles.row}`} >
                            <Col className={styles.boldText}>{t(pname)}</Col>
                            <Col className="d-none d-sm-block">{software}</Col>
                            {/* <Col >{project.theoreticalWeight}</Col> */}
                            <Col >{tonWeight}</Col>
                            <Col className="d-none d-sm-block">{project.activated ? t("Evet") : t("Hayır")}</Col>
                            <Col >{date}</Col>
                        </Row>
                    </Link>
                );
            }
            )}
        </Container>
    );
}

export default MetalProfileProjectsTab;