import React, { useState } from 'react';
import { Col, Container, Form, Row, Button, InputGroup } from 'react-bootstrap';

import { Bs1Circle, Bs2Circle, Bs3Circle, BsCheckCircle } from 'react-icons/bs'
import Modal from 'react-bootstrap/Modal';

import styles from '../ProjectPurchase.module.css'
import SaleContractText from '../../SaleContract/SaleContractText';
import { iso31661_country_codes, iso31662_state_codes } from '../../../other/variables';

import { renderToStaticMarkup } from 'react-dom/server'

import { useTranslation } from 'react-i18next';

const PurchaseDetails = (props) => {

    const id = props.id
    const setStep = props.setStep
    const projectName = props.projectName
    const projectTheoreticalWeight = props.projectTheoreticalWeight
    const unitPrice = props.unitPrice
    const unitPriceType = props.unitPriceType
    const unitDiscount = props.unitDiscount
    const totalPrice = props.totalPrice
    const vat = props.vat
    const discount = props.discount
    const paymentMethod = props.paymentMethod
    const setPaymentMethod = props.setPaymentMethod
    const invoiceInfo = props.invoiceInfo
    const setSaleContract = props.setSaleContract

    const { i18n, t } = useTranslation()

    const clientName = invoiceInfo.type === "real_person" ? `${invoiceInfo.data.name} ${invoiceInfo.data.surname}` : invoiceInfo.data.company_name
    const serviceDetail = <div><a href={`${process.env.REACT_APP_HOME_URL}/${i18n.language}/project-info/${id}`} target='_blank'>{projectName}</a> {t("isimli projenin kesim planı oluşturma hakkı")}</div>
    let date = new Date()
    date = date.toLocaleDateString(i18n.language, { year: 'numeric', month: 'long', day: 'numeric' })

    const saleContractApproved = props.saleContractApproved
    const setSaleContractApproved = props.setSaleContractApproved

    const [modalShow, setModalShow] = useState(false)

    const addressLine2 = invoiceInfo.data.address_line2 !== null ? invoiceInfo.data.address_line2 : ""

    const address = `
        ${invoiceInfo.data.street}
        ${invoiceInfo.data.address_line1}
        ${addressLine2}
        ${invoiceInfo.data.zipcode}
        ${invoiceInfo.data.county} / 
        ${iso31662_state_codes[invoiceInfo.data.province]} / 
        ${iso31661_country_codes[invoiceInfo.data.country]}
    `

    const telephone = `+${invoiceInfo.data.telephone_country_code}${invoiceInfo.data.telephone}`

    const saleContractComponent = <SaleContractText
        type={invoiceInfo.type}
        date={date}
        clientName={clientName}
        tr_identity_number={invoiceInfo.data.tr_identity_number}
        clientTaxno={invoiceInfo.data.taxno}
        clientTelephone={telephone}
        clientAddress={address}
        clientEmail={invoiceInfo.data.email}
        serviceDetail={serviceDetail}
        price={totalPrice}
        paymentMethod={paymentMethod === 1 ? t("Havale / EFT") : t("Kredi / Banka Kartı")}
    />

    const saleContractHtml = renderToStaticMarkup(saleContractComponent)

    const cardHolder = props.cardHolder
    const setCardHolder = props.setCardHolder
    const cardNumber = props.cardNumber
    const cardType = props.cardType;
    const cardTypeLogo = props.cardTypeLogo;
    const handleCardNumberChange = props.handleCardNumberChange
    const handleKeyDown = props.handleKeyDown
    const expirationDate = props.expirationDate
    const handleExpirationDateChange = props.handleExpirationDateChange
    const cvv = props.cvv
    const setCvv = props.setCvv
    const cardHolderError = props.cardHolderError
    const cardError = props.cardError
    const expDateError = props.expDateError
    const cvvError = props.cvvError

    const handleChangePaymentMethod = props.handleChangePaymentMethod
    const handleSubmitOnStepTwo = props.handleSubmitOnStepTwo

    let discountName = ""

    if(unitPriceType === "project_delegate"){
        discountName = t("Temsilcilik İndirimi")
    }
    else if(unitPriceType === "project_with_delegation"){
        discountName = t("Temsilci İndirimi")
    }

    return (
        <Container>
            <Row className='text-center bg-white align-items-center mt-4 border'>
                <Col>
                    <div className={`${styles.statusStep}`}>
                        <span className={`${styles.statusCircle} ${styles.completedCircle}  ${styles.clickable}`} onClick={() => setStep(1)}><BsCheckCircle /></span>
                        <span className={`${styles.statusLabel} ${styles.clickable}`} onClick={() => setStep(1)}>{t("Fatura Detayları")}</span>
                    </div>
                </Col>
                <Col>
                    <div className={`${styles.statusStep}`}>
                        <span className={`${styles.statusCircle}`}><Bs2Circle /></span>
                        <span className={styles.statusLabel}>{t("Ödeme Bilgileri")}</span>
                    </div>
                </Col>
                <Col>
                    <div className={`${styles.statusStep} ${styles.disabled}`}>
                        <span className={`${styles.statusCircle} ${styles.disabledCircle}`}><Bs3Circle /></span>
                        <span className={styles.statusLabel}>{t("Siparişi Tamamla")}</span>
                    </div>
                </Col>
            </Row>

            <Row className='mt-4'>
                <Col className='border bg-white p-4' md={7}>
                    <h5 className='mb-3'>{t("Ödeme Yöntemi")}</h5>
                    <div key={`inline-radio`} className="mb-3">
                        <Form.Check
                            inline
                            label={t("Havale / EFT")}
                            name="group1"
                            type="radio"
                            id={`inline-radio-1`}
                            checked={paymentMethod === 1}
                            value="1"
                            onChange={handleChangePaymentMethod}
                        />
                        <Form.Check
                            inline
                            // disabled
                            label={t("Kredi / Banka Kartı")}
                            name="group2"
                            type="radio"
                            id={`inline-radio-2`}
                            checked={paymentMethod === 2}
                            value="2"
                            onChange={handleChangePaymentMethod}
                        />
                    </div>

                    {paymentMethod === 1 ?
                        <>
                            <div>
                                {t("Havale / EFT ile ödeme yapmak için:")}
                                <ol>
                                    <li>{t("Siparişinizi tamamladıktan sonra, ekranda göreceğiniz IBAN adresine")} <span style={{ fontWeight: "bold" }}>₺{totalPrice}</span> {t("tutarında para transferi yapmanız gerekmektedir.")}</li>
                                    <li>{t("Para transferi açıklamasında, siparişinizi tamamlamınızın ardından oluşturulacak sipariş numarasını yazmayı unutmamalısınız.")}</li>
                                    <li>{t("invoicedetails_reeciptmsg1")} {process.env.REACT_APP_CONTACT_EMAIL} {t("invoicedetails_reeciptmsg2")}</li>
                                </ol>
                            </div>
                            <div className='mb-2'>{t("Para transferinizin incelenmesinin ardından faturanız e-posta adresinize gönderilecek ve projeniz için oluşturacağınız bütün kesim planlarının detaylarına erişebileceksiniz.")}</div>
                            <div className='mb-2'>{t("Para yatırma işlemini siparişinizi oluşturmanızdan sonraki")} <span className='fw-bold'>{t("1 hafta")} </span> {t("içerisinde gerçekleştirmeniz gerekmektedir. 1 hafta içerisinde ödemesi tamamlanmayan siparişler iptal edilecektir.")}</div>
                            <div>{t("Eksik veya yanlış para transferi yapmanız hâlinde para iadesi işleminiz bir haftayı bulabilir.")}</div>
                        </>
                        :
                        <div>
                            <InputGroup className='mb-3'>
                                <InputGroup.Text>
                                    {t("Kart Sahibi")}
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={cardHolder}
                                    onChange={(e) => { setCardHolder(e.target.value) }}
                                    maxLength="100"
                                    placeholder={t("Kart Sahibinin Adı Soyadı")}
                                />
                            </InputGroup>

                            <InputGroup className='mb-3'>
                                <InputGroup.Text>
                                    {t("Kart No")}
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    value={cardNumber}
                                    onChange={handleCardNumberChange}
                                    onKeyDown={handleKeyDown}
                                    maxLength="19"
                                    placeholder={t("Kart Numarası Girin")}
                                />
                                {cardType !== "Unknown" ?
                                    <InputGroup.Text className='bg-white'>
                                        <img src={cardTypeLogo} width="35px"></img>
                                    </InputGroup.Text> : <></>
                                }
                            </InputGroup>
                            <Row>
                                <Col>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>
                                            {t("SKT")}
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            value={expirationDate}
                                            onChange={handleExpirationDateChange}
                                            onKeyDown={handleKeyDown}
                                            maxLength="5"
                                            placeholder={t("Son Kul. Tarihi")}
                                        />
                                    </InputGroup>
                                </Col>
                                <Col>
                                    <InputGroup className='mb-3'>
                                        <InputGroup.Text>
                                            CVV
                                        </InputGroup.Text>
                                        <Form.Control
                                            type="text"
                                            value={cvv}
                                            onChange={(e) => setCvv(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                            maxLength="3"
                                            placeholder="CVV"
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <p style={{ color: "red" }}>{cardHolderError}</p>
                            <p style={{ color: "red" }}>{cardError}</p>
                            <p style={{ color: "red" }}>{expDateError}</p>
                            <p style={{ color: "red" }}>{cvvError}</p>
                            <p>{t("Tasnifer kart bilgilerinizi saklamaz.")}</p>
                        </div>}


                </Col>
                <Col className='border bg-white p-4' md={5}>
                    <h5>{t("Satın Alınan")}</h5>
                    <table className='table table-borderless table-sm'>
                        <tbody>
                            <tr style={{ verticalAlign: "middle", }}>
                                <td>{serviceDetail}</td>
                                <td className='text-end' style={{ fontSize: "16pt", fontWeight: "bold", }}>₺{Math.floor(projectTheoreticalWeight * (unitPrice + unitDiscount) * 1e-3)}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ textAlign: "end", fontSize: "9pt", color: "#777777" }}>
                                    {(projectTheoreticalWeight * 1e-3).toFixed(3)} ton &times; {unitPrice + unitDiscount} ₺/ton
                                </td>
                            </tr>
                            {unitDiscount !== 0 &&
                                <>
                                    <tr style={{ verticalAlign: "middle", }}>
                                        <td>{discountName}</td>
                                        <td className='text-end' style={{ fontSize: "16pt", fontWeight: "bold", }}>&minus; ₺{Math.floor(projectTheoreticalWeight * (unitDiscount) * 1e-3)}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ textAlign: "end", fontSize: "9pt", color: "#777777" }}>
                                            {(projectTheoreticalWeight * 1e-3).toFixed(3)} ton &times; &minus;{unitDiscount} ₺/ton
                                        </td>
                                    </tr>
                                </>
                            }
                            {paymentMethod === 1 &&
                                <>
                                    <tr style={{ verticalAlign: "middle", }}>
                                        <td>%{(props.discount * 100).toFixed(1)} {t("Havale İndirimi")}</td>
                                        <td className='text-end' style={{ fontSize: "16pt", fontWeight: "bold", }}>&minus; ₺{props.initialPrice - totalPrice}</td>
                                    </tr>
                                </>
                            }
                            <tr style={{ verticalAlign: "middle", }} className='border-top'>
                                <td>{t("Ödenecek tutar")}</td>
                                <td className='text-end' style={{ fontSize: "16pt", fontWeight: "bold", }}>₺{totalPrice}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} style={{ textAlign: "end", fontSize: "9pt", color: "#777777" }}>
                                    {`(%${100 * vat} KDV${vat === 0 ? "" : " Dahil"})`}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <hr></hr>
                    <Form onSubmit={(e) => handleSubmitOnStepTwo(e, saleContractHtml)}>
                        <Form.Check
                            label={<div>{t("purchasedetails_contractmsg1")} <a style={{ cursor: 'pointer' }} href="#" onClick={(e) => { e.preventDefault(); setModalShow(true) }}>{t("Satış Sözleşmesi")}</a>{t("purchasedetails_contractmsg2")}</div>}
                            name="group1"
                            required
                            checked={saleContractApproved}
                            onChange={(e) => { setSaleContractApproved(e.target.checked) }}
                            type="checkbox"
                            id="sale-contract-check">

                        </Form.Check>
                        <div className='text-center mt-3'>
                            <Button
                                size='lg'
                                type='submit'
                            >
                                {t("İLERLE")}
                            </Button>
                        </div>

                    </Form>

                </Col>

            </Row>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {t("Satış Sözleşmesi")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {saleContractComponent}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>

        </Container>

    );
};

export default PurchaseDetails;
